import { configureStore } from '@reduxjs/toolkit';

// Common importsp
import navbarTopSlice from './common/slices/navbarTop';
import createUserReducer from './features/createUser/createUserSlice';
import dashboardReducer from './features/dashboard/slices/dashboardSlice';
import instructionReducer from './features/dashboard/slices/instructionSlice';
import creditBalanceReducer from './common/slices/creditBalanceSlice';
import notificationReducer from './common/slices/notificationSlice';
import requestedTableReducer from './features/dashboard/slices/requestedTableSlice';
import releasedTableReducer from './features/dashboard/slices/releasedTableSlice';
import docPendingTableReducer from './features/dashboard/slices/docPendingTableSlice';
import documentHistoryReducer from './features/blDetails/slices/documentHistorySlice';
import blDetailsTableReducer from './features/blDetails/slices/blDetailsTable';
import blPaymentReducer from './features/blPayment/slices/blPaymentSlice';
import creditBalanceBlPaymentReducer from './features/blPayment/slices/creditBalance';
import paymentBlPaymentReducer from './features/blPayment/slices/PaymentInvoiceSlice';
import customerMsgTableReducer from './features/dashboard/slices/customerMsgTable';
import BlRequestInsReducer from './features/blRequestInstruction/blRequestInsSlice';
import MultiBlPaymentReducer from './features/multiBlPayment/slices/multiBlPaymentSlice';
import PaymentReducer from './features/payment/paymentSlice';
import RegistrationReducer from './features/registration/registraionSlice';
import NominationReq from './features/nominationReq/slices/nominationReq';
import StandingInstructionTable from './features/standingInstruction/slices/standingInstructionTable';
import DataTableReducer from './common/components/DataTable/slices/DataTable';
import ThemeSettingsReducer from './features/settings/slices/themeSettings';
import DocumentViewReducer from './common/slices/documentViewSlice';
import NavbarVerticalReducer from './common/slices/navbarVertical';
import ColumnConfigurationReducer from './features/settings/slices/columnConfiguration';
import FilterViewCommon from './common/slices/filterView';
import RegistrationCompanyReducer from './features/registration/registrationCompanySlice';
import CoreorReducer from './features/coreor/coreorSlice';
import OblDetailReducer from './common/components/OblDetailSlice';
import CarrierHaulageSlice from './features/blRequestInstruction/components/instructionSlice/CarrierHaulageSlice';
import InvoiceChangeSlice from './features/blRequestInstruction/components/instructionSlice/InvoiceChangeSlice';
import DashboardCSVSlice from './features/dashboard/slices/dashboardcsvSlice';
import AgentNominationSlice from './features/blRequestInstruction/components/instructionSlice/AgentNominationSlice';
import DemurrageSlice from './features/blRequestInstruction/components/instructionSlice/DemurrageSlice';
import DemurrageSliceView from './features/blRequestInstruction/components/instructionSlice/DemurrageSlice';
import DemurrageViewSlice from './features/blRequestInstruction/components/instructionSlice/DemurrageViewSlice';
import loginSlice from './features/registration/loginSlice';
import ProfileAccountSlice from './features/profile/profileAccountSlice';
import userDetailsSlice from './common/slices/userDetails';
import userDetailsListReducers from './features/createUser/UserDetailsSlice';
import { instructionDashboardSlice } from './features/instructionDashboard/slices/dashboardSlice';
import { instructionDetailsTableSlice } from './features/instructionDashboard/slices/instructionDetailsTable';
import ReturnSlice from './features/blRequestInstruction/components/instructionSlice/ReturnSlice';
import ReturnViewSlice from './features/blRequestInstruction/components/instructionSlice/ReturnViewSlice';
import PickupSlice from './features/blRequestInstruction/components/instructionSlice/PickupSlice';
import { paymentBlPayment } from './features/blPayment/slices/PaymentInvoiceSlice';
import standingInstructionTableSlice from './features/standingInstruction/slices/standingInstructionTable';
import loadingMiddleware, { loadingSlice } from '../middleware/loadingMiddleware';
import standaloneInvoicePaymentSlice from './features/blPayment/slices/standaloneInvoicePaymentSlice';
import { paymentDashboardSlice } from './features/paymentdashboard/paymntDashSlice';
import { registerDashboardSlice } from './features/registrationMaster/slices/dashboardSlice';
import { reportDashboardSlice } from './features/reportDashboard/reportSlice';
import { snoozeSlice } from './features/dashboard/slices/snoozeSlice';
import unpaidReducer from './features/crrpop/InvoiceUnpaidSlice'
import { overstayExtensionSlice } from './features/blRequestInstruction/components/instructionSlice/OverstayExtensionSlice';
import quickPayReducer from './features/quickpay/quickPaySlice'
import MappingDashSlice, { mappingDashboardSlice } from './features/customerMappingDashboard/MappingSlice';
import { getInGetOutDashboardSlice } from './features/getInGetOutDashboard/getInGetOutDashSlice';

export const store = configureStore({
  reducer: {
    createUser: createUserReducer,
    dashboard: dashboardReducer,
    creditBalance: creditBalanceReducer,
    notification: notificationReducer,
    requestedTable: requestedTableReducer,
    releasedTable: releasedTableReducer,
    docPendingTable: docPendingTableReducer,
    documentHistory: documentHistoryReducer,
    blDetailsTable: blDetailsTableReducer,
    customerMsgTable: customerMsgTableReducer,
    blPayment: blPaymentReducer,
    creditBalanceBlPayment: creditBalanceBlPaymentReducer,
    paymentBlPayment: paymentBlPaymentReducer,
    blRequestInstruction: BlRequestInsReducer,
    multiBlPayment: MultiBlPaymentReducer,
    payment: PaymentReducer,
    nominationReq: NominationReq,
    standingInstructionTable: StandingInstructionTable,
    dataTable: DataTableReducer,
    themeSettings: ThemeSettingsReducer,
    instructionSlice: instructionReducer,
    documentView: DocumentViewReducer,
    navbarVertical: NavbarVerticalReducer,
    columnConfiguration: ColumnConfigurationReducer,
    filterViewCommon: FilterViewCommon,
    registration: RegistrationReducer,
    registrationCompany: RegistrationCompanyReducer,
    coreor: CoreorReducer,
    oblDetails: OblDetailReducer,
    carrierHaulage: CarrierHaulageSlice,
    invoiceChange: InvoiceChangeSlice,
    dashboardcsv: DashboardCSVSlice,
    navbarTop: navbarTopSlice,
    agentDelegation: AgentNominationSlice,
    demurrage: DemurrageSlice,
    demurrageView: DemurrageSliceView,
    demurrageViewSlice: DemurrageViewSlice,
    loginDetails: loginSlice,
    partnerData: ProfileAccountSlice,
    userProfile: userDetailsSlice,
    userList: userDetailsListReducers,
    standingInstructionList: standingInstructionTableSlice,
    instructionDashboard: instructionDashboardSlice.reducer,
    registrationDashboard: registerDashboardSlice.reducer,
    instructionTable: instructionDetailsTableSlice.reducer,
    return: ReturnSlice,
    returnView: ReturnViewSlice,
    pickup: PickupSlice,
    standaloneInvoice: standaloneInvoicePaymentSlice,
    paymentDashboard: paymentDashboardSlice.reducer,
    reportDashboard: reportDashboardSlice.reducer,
    loading: loadingSlice.reducer,
    snooze:snoozeSlice.reducer,
    invoiceUnpaid: unpaidReducer,
    overstay:overstayExtensionSlice.reducer,
    quickpay:quickPayReducer,
    mappingDashboard: mappingDashboardSlice.reducer,
    getIngetOut:getInGetOutDashboardSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([loadingMiddleware]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
