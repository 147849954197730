import { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import is from "is_js";
import MainLayout from "./MainLayout";
import SettingsPanel from "../components/settings-panel/SettingsPanel";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { CloseButton } from "../components/common/Toast";

import AppContext from "../context/Context";
import Landing from "../app/components/pages/landing/Landing";

import ErrorLayout from "./ErrorLayout";
import Error404 from "../components/errors/Error404";
import Error500 from "../components/errors/Error500";

import BlDetails from "../app/features/blDetails/BlDetails";
import BlPayment from "../app/features/blPayment/BlPayment";
import Payment from "../app/features/payment/Payment";
import AuthCardLayout from "./AuthCardLayout";
import Registration from "../app/features/registration/Registration";

import DocumentView from "../app/common/components/DocumentView";
import StandingInstruction from "../app/features/standingInstruction/StandingInstruction";
import PaymentDashboard from "../app/features/paymentdashboard/PaymentDashboard";

import { useLocation } from "react-router-dom";
import queryString from "query-string";
import ResetPassword from "../app/features/registration/ResetPassword";
import ForgotPassword from "../app/features/registration/ForgotPassword";
import LoginForm from "../app/features/registration/LoginForm";
import ProfileAccount from "../app/features/profile/ProfileAccount";
import { useSelector } from "react-redux";
import AccountDtls from "../app/features/profile/AccountDtls";
import UserDetails from "../app/features/createUser/UserDetails";
import User from "../app/features/createUser/User";
import CustomerMappingDtls from "../app/features/profile/CustomerMappingDtls";
import InstructionDtls from "../app/features/instructionDashboard/instructionDetails";
import PaymentFund from "../app/features/paymentFund/PaymentFund";
import ConsumerNominateAgent from "../app/features/dashboard/components/ConsumerNominateAgent";
import ConsigneeNominateAgent from "../app/features/dashboard/components/ConsigneeNominateAgent";
import BlanketDelegation from "../app/features/dashboard/components/BlanketDelegation";
import InstructionMapper from "../components/common/InstructionMapper";
import RegistrationDetails from "../app/features/registrationMaster/components/registrationDetails";
import { AESDecrypt } from "../encrypt-util";

import ReportDashboard from "../app/features/reportDashboard/ReportDashboard";
import Snooze from "../app/features/blRequestInstruction/components/Snooze";
import InvoiceUnpaid from "../app/features/crrpop/InvoiceUnpaid";
import QuickPay from "../app/features/quickpay/QuickPay";
import QuickPayment from "../app/features/quickpay/PaymentQuickPay";
import Commonapp from "../app/components/Commonapp";
import AuthCardLayoutNew from "./AuthCardLayoutNew";
import ReceiptDashboard from "../app/features/receiptDashboard/receiptDashboard";
import GetInGetOutDashboard from "../app/features/getInGetOutDashboard/getInGetOutDashboard";
import MappingDashboard from "../app/features/customerMappingDashboard/MappingDashboard";
import CustomerDetailsSlider from "../app/features/customerMappingDashboard/MappingCustomerDetailsSlider";
import EditUserSlider from "../app/features/customerMappingDashboard/MappingEditUserSlider";
import AddCodeSlider from "../app/features/customerMappingDashboard/MappingAddCodeSlider";
import OblDetails from "../app/common/components/OblDetails";
const Layout = () => {
  const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  const profileDetails = useSelector((state) => state.userProfile.profile);
  const gridName = useSelector(
    (state) => state.navbarTop.selectedDashbaordName
  );
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add("windows");
    }
    if (is.chrome()) {
      HTMLClassList.add("chrome");
    }
    if (is.firefox()) {
      HTMLClassList.add("firefox");
    }
  }, [HTMLClassList]);

  const location = useLocation();

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query?.setActiveUser) {
      window.localStorage.setItem("@odex/activeuser", query.setActiveUser);
      window.location.href = "/home";
    }
  }, []);
  const parts = location.pathname.split("/");
  const page = parts[parts.length - 1];
  const decpage = parts.length != 3 ? "" : AESDecrypt(page);

  return (
    <>
      <Routes>
        <>
          <Route path="/" element={<Landing />} />

          <Route path="/app" element={<Commonapp />} />

          <Route element={<AuthCardLayoutNew />}>
            <Route path="register" element={<Registration />} />
          </Route>

          <Route path="resetpassword" element={<ResetPassword />} />
          <Route path="forgotPassword" element={<ForgotPassword />} />
          <Route path="quickPay" element={<QuickPay />}>
            <Route path="pay" element={<QuickPayment />} />
          </Route>

          <Route element={<MainLayout />}>
            <Route path="user/profile" element={<ProfileAccount />}>
              <Route path="details/:accountId" element={<AccountDtls />} />
              <Route
                path="custMapDetails/:custMapId"
                element={<CustomerMappingDtls />}
              />
            </Route>

            <Route path="mappingdashboard" element={<MappingDashboard />}>
              <Route path="customerdetails/:custId" />
              <Route
                path="customerdetails/:custId/edituser"
                element={<EditUserSlider />}
              />
              <Route
                path="customerdetails/:custId/edituser/:userId"
                element={<EditUserSlider />}
              />
              <Route
                path="customerdetails/:custId/addcode"
                element={<AddCodeSlider />}
              />
            </Route>

            <Route path="payment-fund" element={<PaymentFund />} />
            <Route path="paymentDashboard" element={<PaymentDashboard />} />
            <Route path="receipt" element={<ReceiptDashboard />} />
            <Route path="gateinout" element={<GetInGetOutDashboard />} />
            <Route path="users" element={<UserDetails />}>
              <Route path="details/:userID" element={<User />} />
            </Route>
            <Route path="/home" element={<BlDetails />} />
            <Route path="blslider" element={<BlDetails />}>
              <Route path=":blNum" element={<OblDetails />} />
            </Route>
            <Route path="blsliderinvoice/:blNum" element={<Payment />} />
            <Route
              path="blsliderpayment/:blNum"
              element={
                gridName == "PAYMENT_DASHBOARD" ? (
                  <PaymentDashboard />
                ) : (
                  <Payment />
                )
              }
            >
              <Route index element={<BlPayment />} />
            </Route>
            <Route path="payment" element={<Payment />}>
              <Route path=":blNum" />
              <Route path="new" element={<BlPayment />} />
              <Route path="addInvoice">
                <Route path="invoicePayment" element={<BlPayment />} />
              </Route>
            </Route>
            <Route path="report" element={<ReportDashboard />} />
            {profileDetails?.partnerType !== "liner" && (
              <Route path="multiBl/:blNum" element={<BlDetails />}>
                <Route
                  path="instruction/:encrypt"
                  element={<InstructionMapper />}
                />
              </Route>
            )}
            {/*Standing Dashboard*/}
            <Route path="standing" element={<StandingInstruction />}>
              <Route path=":encrypt" element={<BlanketDelegation />} />
              <Route
                path=":blNum/:encrypt/:encryptdoc"
                element={<BlanketDelegation />}
              />
            </Route>
            {/*Instruction Dashboard*/}
            <Route path="instruction" element={<InstructionDtls />}>
              <Route path="snooze/:blEventId" element={<Snooze />}></Route>
              <Route path=":blNum" />
              <Route
                path=":blNum/:encrypt/:encryptdoc"
                element={<InstructionMapper />}
              />
              {/*liner */}
              {(decpage == "811" || decpage == "815") && (
                <Route path={page} element={<ConsumerNominateAgent />} />
              )}
              {/*customer*/}
              {(decpage == "812" || decpage == "813") && (
                <Route path={page} element={<ConsigneeNominateAgent />} />
              )}
              <Route path="bl/:blNum">
                <Route path=":encrypt" element={<InstructionMapper />} />
                <Route
                  path=":encrypt/:encryptdoc"
                  element={<InstructionMapper />}
                />
                <Route path="snooze/:blEventId" element={<Snooze />}></Route>
                <Route path=":docType" element={<DocumentView />} />
                <Route path="payment/:blId" element={<BlPayment />} />
                <Route path="unpaid" element={<InvoiceUnpaid />} />
                <Route path="unpaid/payment" element={<BlPayment />} />
                {profileDetails?.partnerType !== "liner" && (
                  <Route path="instruction">
                    <Route path=":encrypt" element={<InstructionMapper />} />
                    <Route
                      path=":encrypt/:blId"
                      element={<InstructionMapper />}
                    />
                  </Route>
                )}
              </Route>
            </Route>
            {/*Resgistration Master Dashboard*/}
            <Route
              path="registrationmaster"
              element={<RegistrationDetails />}
            />
            <Route
              path="blsliderinstruction/:blNum"
              element={<InstructionDtls />}
            />
            <Route
              path="registrationmaster/:requestId"
              element={<RegistrationDetails />}
            />
            {profileDetails?.partnerType === "liner" ? (
              <Route path="payment/bl/:blNum" element={<Payment />}>
                <Route path=":encrypt" element={<InstructionMapper />} />
                <Route
                  path=":encrypt/:encryptdoc"
                  element={<InstructionMapper />}
                />
                <Route path="snooze/:blEventId" element={<Snooze />}></Route>
                <Route path=":docType" element={<DocumentView />} />
                <Route path="payment/:blId" element={<BlPayment />} />
              </Route>
            ) : (
              <Route path="payment/bl/:blNum" element={<Payment />}>
                <Route path=":encrypt" element={<InstructionMapper />} />
                <Route
                  path=":encrypt/:encryptdoc"
                  element={<InstructionMapper />}
                />
                <Route path=":docType" element={<DocumentView />} />
                <Route path="unpaid" element={<InvoiceUnpaid />} />
                <Route path="unpaid/payment" element={<BlPayment />} />
                <Route path="instruction">
                  <Route path=":encrypt" element={<InstructionMapper />} />
                  <Route
                    path=":encrypt/:blId"
                    element={<InstructionMapper />}
                  />
                </Route>
                <Route path="payment/:blId" element={<BlPayment />} />
              </Route>
            )}
            <Route path="bl/:blNum" element={<BlDetails />}>
              <Route path="payment/:blId" element={<BlPayment />} />
              <Route path=":encrypt" element={<InstructionMapper />} />

              <Route path="snooze/:blEventId" element={<Snooze />}></Route>

              <Route
                path=":encrypt/:encryptdoc"
                element={<InstructionMapper />}
              ></Route>
              <Route path=":docType" element={<DocumentView />} />
              <Route path="unpaid/payment" element={<BlPayment />} />
              <Route path="unpaid" element={<InvoiceUnpaid />} />
              {profileDetails?.partnerType !== "liner" && (
                <Route path="instruction">
                  <Route path=":encrypt" element={<InstructionMapper />} />
                  <Route
                    path=":encrypt/:blId"
                    element={<InstructionMapper />}
                  />
                </Route>
              )}
            </Route>
            <Route path="ebl/:blNum" element={<BlDetails />}>
              
            </Route>
          </Route>

          {/* //--- MainLayout Starts  */}

          {/* //--- MainLayout end  */}

          <Route element={<ErrorLayout />}>
            <Route path="errors/404" element={<Error404 />} />
            <Route path="errors/500" element={<Error500 />} />
          </Route>
        </>
      </Routes>
      <SettingsPanel />
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.TOP_RIGHT}
      />
    </>
  );
};

export default Layout;
