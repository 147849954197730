import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";
import {
  faCheck,
  faLocation,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Divider from "../../../components/common/Divider";
import { useCallback, useEffect, useRef, useState } from "react";
import Select, {
  ClearIndicatorProps,
  components,
  MultiValueRemoveProps,
} from "react-select";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  approveRejectRegistration,
  fetchAddressSuggestions,
  fetchLinerNameByCountry,
  getCompanyTypes,
  getCountryList,
  getPortOperatorTypes,
  getRegistrationDetails,
  getRegistrationIndemnityDocuments,
  getRegistrationTaxDocConfig,
  getRegistrationTaxDocVerify,
  getSeaPortTypes,
  getShippingLineTypes,
  registerCustomerAndUser,
  taxIDValidation,
} from "./actions/Registration";
import { useSelector } from "react-redux";
import {
  clearRegistrationFiles,
  resetPartenerTable,
  setIsSubmitted,
  updateIndemnityDocument,
  updateSelectedAddressResult,
  updateSelectedCountry,
  updateTaxDocument,
  validationCheckRegistration,
} from "./registraionSlice";

import { useAsyncDebounce } from "react-table";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { Modal } from "react-bootstrap";
import TermsAndConditions from "./termsAndCondition";
import PrivacyPolicy from "./privacyPolicy";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { updateRegistrationMaster } from "../registrationMaster/slices/dashboardSlice";
import { AESDecrypt } from "../../../encrypt-util";
import {
  getGroupListRegistrationMaster,
  getGroupListRegistrationMasterOPC,
} from "../createUser/action/UserDetails";
import { useLoading } from "../../../middleware/loadingMiddleware";
import RegistrationPartnerTable from "./RegistrationPartnerTable";

import RegistrationDocumentTable from "./RegistrationDocumentTable";
import { updateDashbaordRefresh } from "../../common/components/DataTable/slices/DataTable";
import InfoDiv from "../../../components/common/InfoDiv";
import IndemnityLetter from "./indemnityLetter";
import CreatableSelect from "react-select/creatable";
import CheckStatus from "./CheckStatus";
import { count } from "console";

import { customDropdownStyles } from "../../common/customStyles";
import UploadThing from "../../../components/common/UploadThing";
//import { WithContext as ReactTags } from "react-tag-input";
import RegistrationDocumentTableTz from "./RegistrationDocumentTableTz";

const Registration = () => {
  const indemnityDocumentManditoryCheck = useSelector(
    (state: RootState) => state.registration.IndemnityLetterManditoryCheck
  );
  const documentOptions = useSelector(
    (state: RootState) => state.registration.documentOptions
  );
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const isMandatory = indemnityDocumentManditoryCheck[0]?.isMandatory;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const KeyCodes = { comma: 188, enter: 13, };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  /* const handleDelete = (i:any) => {
    setValue('cfsCode', watch("cfsCode").filter((tag:any, index:number) => index !== i));
  };
 
  const handleAddition = (tag:any) => {
    setValue('cfsCode',[...watch("cfsCode"), tag]);
  };
   */

  type FormValues = {
    country: string;
    //ein: string;
    userName: string;
    taxID: string;
    faxNumber: string;
    indemnityLetter: string;
    companyName: string;
    primaryContact: string;
    email: string;
    phoneNumber: string;

    address: string;
    acceptTerms: boolean;
    countryCode: string;
    liner: string;
    customerCode: string;
    companyType: Array<string | number>;
    companyTypeOpc: string;
    seaPort: string;
    portOperator: Array<string>;
    portServicesRadio: boolean;
    shippingLines: Array<string>;
    acceptCondition: boolean;
    isloiapproved: string;
    isIdentityNoApproved: string;
    // emptyYardCode:string;
    //cfsCode:Array<any>;
    cfsCode: string;
  };

  const validationSchema = Yup.object().shape({
    // companyName: Yup.string().required("Company Name is required"),
    companyName: Yup.string()
      .required("Company Name is required")
      .when("country", {
        is: "TZ",
        then: Yup.string().min(
          3,
          "Company Name must be at least 3 characters long"
        ),
      }),
    phoneNumber: Yup.string()
      .when("country", {
        is: "TZ",
        then: Yup.string().required("Phone Number is required")
      }),
    // indemnityLetter: Yup.string().when("country", {
    //   is: (country: any) =>
    //     (country === "TZ" && isMandatory == "Y") || country === "TZ", // Check if country is "TZ" or isMandatory is "Y" (or if country is TZ then regardless required)
    //   then: Yup.string().required("Indemnity Letter is required"),
    //   otherwise: Yup.string(),
    // }),
    // ein: Yup.string().required('EIN is required'),
    userName: Yup.string().required("User Name is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    country: Yup.string().required("Country is required"),
    seaPort: Yup.string().when("country", {
      is: "TZ",
      then: Yup.string().required("Sea Port is required"),
      otherwise: Yup.string(),
    }),
    /*  emptyYardCode: Yup.string().when("companyType",{
       is: (cType:[any])=>false && cType.includes('4'),
       then: Yup.string().required('Empty Yard Code is required'),
       otherwise: Yup.string()
     }), */
    cfsCode: Yup.string().when("companyType", {
      is: (val: any) => val?.includes?.('3') || val?.includes?.(3),
      then: Yup.string().required('CFS Code is required'),/* .min(1, 'CFS Code is required'), */
      otherwise: Yup.string()
    }),
    portOperator: Yup.array()
      .of(Yup.string())
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === null || originalValue === undefined) {
          return [];
        }
        return Array.isArray(originalValue) ? originalValue : [originalValue];
      })
      .when("country", {
        is: "TZ",
        then: Yup.array().min(
          1,
          "At least one Port/Terminal Operator is required"
        ),
        otherwise: Yup.array(),
      }),
    shippingLines: Yup.mixed().when(["country", "companyTypeOpc"], {
      is: (country: any, companyTypeOpc: any) =>
        country === "TZ" && companyTypeOpc?.includes("12"),
      then: Yup.array()
        .of(Yup.string())
        .nullable()
        .transform((value, originalValue) => {
          if (originalValue === null || originalValue === undefined) {
            return [];
          }
          return Array.isArray(originalValue) ? originalValue : [originalValue];
        })
        .min(1, "At least one Shipping Line is required")
        .required("At least one Shipping Line is required"),
      otherwise: Yup.array().nullable(), // Specify what to do if conditions are not met
    }),
    companyTypeOpc: Yup.string().when("country", {
      is: "TZ",
      then: Yup.string().required("Company Type is required"),
      otherwise: Yup.string(),
    }),
    companyType: Yup.array().when(["country", "companyTypeOptions"], {
      is: (country: string, options: any[]) =>
        country !== "TZ" && options?.length > 0,
      then: Yup.array()
        .required("Company Type is required")
        .min(1, "Company Type is required"),
      otherwise: Yup.array(),
    }),
    //primaryContact: Yup.string().required('Primary Contact is required'),
    // phoneNumber: Yup.string()
    //   .required('Phone Number is required')
    //   .matches(
    //     /(\d{0,3})-(\d{0,3})-(\d{4}$)/,
    //     'Invalid phone number format. It should match the pattern "###-###-####"'
    //   ),
    taxID: Yup.string().required(
      t("resource_3:isRequired", { field: t("resource_2:taxId") })
    ),

    /*  .when('country', {
        is: 'USA',
        then: Yup.string().matches(
          /^\d\d-\d{7}$/,
          'Invalid input format. It should match the pattern "DD-#######"'
        ),
      }), */
    address: Yup.string().required("Address is required"),
    acceptTerms: Yup.boolean().oneOf(
      [true],
      "You need to accept the terms and conditions"
    ),
    /*  acceptCondition: Yup.boolean().oneOf(
      [true],
      "You need to accept the terms and conditions"
    ), */
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    clearErrors,
    formState: { errors },
    setValue,
    trigger,
    setError,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  const countrywatch = watch("country");

  const [taxidvalidval, setTaxIdValidVal] = useState();

  const companyName1 = useSelector(
    (state: RootState) => state.registration.companyName
  );
  const isSubmitted = useSelector(
    (state: RootState) => state.registration.isSubmitted
  );
  const address1 = useSelector(
    (state: RootState) => state.registration.address
  );
  const taxDocument = useSelector(
    (state: RootState) => state.registration.taxDocument
  );
  /*  const [taxDocument, setTaxDocument] = useState({
    name: "",
    serverPath: null,
    serverName: null,
    loading: 0,
    m: false,
    error: false,
  }); */
  const indemnityDocument = useSelector(
    (state: RootState) => state.registration.indemnityDocument
  );

  const registrationMasterDisable = useSelector(
    (state: RootState) => state.registrationDashboard.registrationMasterDisable
  );

  const registrationdata = useSelector(
    (state: RootState) => state.registration
  );
  const countryOptions = useSelector(
    (state: RootState) => state.registration.countryOptions
  );
  const portOperatorOptions = useSelector(
    (state: RootState) => state.registration.portOperatorOptions
  );

  const shippingLineOptions = useSelector(
    (state: RootState) => state.registration.shippinglineOptions
  );
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);
  const companyTypeOptions: any = useSelector(
    (state: RootState) => state.registration.companyTypeOptions
  );
  const seaPortOptions: any = useSelector(
    (state: RootState) => state.registration.seaPortOptions
  );
  const listloading = useLoading([
    "registrationCompany/getCountryList",
    "registration/fetchLinerName",
  ]);

  const dispatch = useDispatch<AppDispatch>();
  const [groupList, setGroupList] = useState([]);
  const [registrationDetails, setRegistrationDetails] = useState({
    countryCode: "",
    customerAddress: "",
    partnerId: "",
    companyTypeOpc: "",
    shippingLines: "",
    userName: "",
    contactNo: "",
    email: "",
    portOperator: "",
    seaPort: "",
    faxNumber: "",
    taxID: "",
    companyName: "",
    language: "",
    opcPartnerType: "",
    loiJsonString: {},
    customerMapping: [
      {
        linerId: "",
        mapCode: "",
      },
    ],
    isTNCAccepted: "",
    mstGrp: [],
    requestId: "",
    mstUsrId: "",
    //emptyYardCode:'',
    cfsCode: "",
    remarks: "",
    status: 0,
    isloiapproved: "",
    indemnityLetter: "",
    isIdentityNoApproved: "",
  });

  const [remarks, setremarks] = useState("");
  const [linerIdForCustomerMapping, SetlinerIdForCustomerMapping] = useState();
  const [seaportTxt, setSeaportTxt] = useState("");
  const [portOperatorText, setPortOperatorText] = useState([]);
  const [shippingLineText, setShippingLineText] = useState([]);
  const [companyTypeText, setCompanyTypeText] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const controlHeight = selectedOptions.length
    ? `${50 + selectedOptions.length * 10}px`
    : "50px";
  const linerListLoading = useLoading(["registration/fetchLinerName"]);

  // const encryptrequestId = location.pathname.split("/")[3];
  var encryptrequestId: any;
  location.pathname.includes("opc")
    ? (encryptrequestId = location.pathname.split("/")[3])
    : (encryptrequestId = location.pathname.split("/")[2]);

  const decrptrequestId = AESDecrypt(encryptrequestId);
  const [cfsCode, setCfsCode] = useState('')
  var bodyFormData = new FormData();
  bodyFormData.append("company_number", watch("taxID"));
  bodyFormData.append("country_code", "US");
  bodyFormData.append("source", "2");
  bodyFormData.append("request_id", "0001");
  bodyFormData.append("state", "NJ");

  bodyFormData.append("company_name", watch("companyName"));
  interface PortOperatorOption {
    value: string;
    label: string;
  }

  const CustomMultiValueRemove = (
    props: MultiValueRemoveProps<PortOperatorOption, boolean>
  ) => {
    const defaultOption = portOperatorOptions[0];
    if (props.data.value === defaultOption.value) {
      return null;
    }
    return <components.MultiValueRemove {...props} />;
  };
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isTaxDocumentVerified, setIsTaxDocumentVerified] = useState("N");
  const [isUserGroupSelected, setIsUserGroupSelected] = useState(false);
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [isUserGroupSelectedTZ, setIsUserGroupSelectedTZ] = useState(
    countrywatch === "TZ"
  );
  const [isIndemnityDocumentVerified, setIsIndemnityDocumentVerified] =
    useState("N");
  useEffect(() => {
    if (countrywatch === "TZ") {
      setCheckedItems((prev) => {
        // Default all checkboxes to checked for TZ
        const newState = { ...prev };
        groupList.forEach((item: any) => {
          newState[item.value] = true;
        });
        return newState;
      });

      // Set default selectedOptions if none are selected
      setSelectedOptions((prevSelectedOptions: any) => {
        if (prevSelectedOptions.length === 0) {
          return groupList.map((item: any) => ({
            mstGrpId: item.value,
            usrGrpNm: item.label,
          }));
        }
        return prevSelectedOptions;
      });

      // Set the user group selected state flags

      setIsUserGroupSelectedTZ(true);
    }
  }, [countrywatch, groupList]);
  useEffect(() => {
    localStorage.removeItem("formData");
  }, []);
  useEffect(() => {
    if (countrywatch === "TZ") {
      dispatch(fetchLinerNameByCountry({ country_code: countrywatch }))
        .unwrap()
        .then((v: any) => {
          SetlinerIdForCustomerMapping(v[0]?.value);
        });
      dispatch(updateTaxDocument({ ...taxDocument, m: false }))
    }
  }, [countrywatch]);

  const [isIndemnityFileUploaded, setIsIndemnityFileUploaded] = useState(false);
  const CustomClearIndicator = (
    props: ClearIndicatorProps<PortOperatorOption, true>
  ) => {
    const { getValue } = props;
    const selectedOptions = getValue();

    // Only show the clear indicator if more than one option is selected
    if (selectedOptions.length <= 1) {
      return null;
    }

    return <components.ClearIndicator {...props} />;
  };
  useEffect(() => {
    // return () => {
    if (prevCountryRef.current !== countrywatch) {
      return () => {
        localStorage.removeItem("formData");
        dispatch(setIsSubmitted(false));
      };
    }
    //   };
  }, [countrywatch]);

  useEffect(() => {
    if (registrationDetails.isIdentityNoApproved === "Y") {
      setIsTaxDocumentVerified("Y");
    } else {
      setIsTaxDocumentVerified("N");
    }
  }, [registrationDetails.isIdentityNoApproved, decrptrequestId]);

  useEffect(() => {
    if (registrationDetails.isloiapproved === "Y") {
      setIsIndemnityDocumentVerified("Y");
    } else {
      setIsIndemnityDocumentVerified("N");
    }
  }, [registrationDetails.isloiapproved, decrptrequestId]);

  useEffect(() => {
    dispatch(clearRegistrationFiles());
    dispatch(getCountryList(1))
      .unwrap()
      .then((data) => {
        if (
          !location.pathname.includes("/registrationmaster") &&
          data?.[0]?.value
        ) {
          setValue("country", data?.[0]?.value);
        }
      });
  }, []);
  /*   useEffect(() => {
    if (countrywatch || taxDocument) {
      dispatch(getRegistrationTaxDocVerify({ country: countrywatch }));

    }
  }, [countrywatch ]); */

  const debouncedDispatch = useCallback(
    debounce(() => {
      dispatch(getRegistrationIndemnityDocuments(countrywatch));
    }, 300),
    [countrywatch]
  );

  useEffect(() => {
    if (countrywatch && prevCountryRef.current !== countrywatch) {
      debouncedDispatch();
    }
  }, [countrywatch, debouncedDispatch]);

  const debouncedDispatchTaxDoc = useCallback(
    debounce(() => {
      dispatch(
        getRegistrationTaxDocConfig({ country: countrywatch.substring(0, 2) })
      );
    }, 300),
    [countrywatch]
  );

  useEffect(() => {
    if (countrywatch && prevCountryRef.current !== countrywatch) {
      debouncedDispatch();
    }
  }, [countrywatch, debouncedDispatchTaxDoc]);

  const debouncedDispatchTaxVerify = useCallback(
    debounce(() => {
      dispatch(getRegistrationTaxDocVerify({ country: countrywatch }));
    }, 300), // adjust debounce delay as needed
    [countrywatch]
  );

  useEffect(() => {
    if (countrywatch && prevCountryRef.current !== countrywatch) {
      debouncedDispatchTaxVerify();
    }
  }, [countrywatch, debouncedDispatchTaxVerify]);
  useEffect(() => {
    /*   if (countrywatch == "TZ") {
      dispatch(getRegistrationIndemnityDocuments(countrywatch));
    } */
    if (countrywatch /* && prevCountryRef.current !== countrywatch */) {
      setValue("companyType", []);
      setValue("cfsCode", "");
      setValue("companyName", "");
      setValue("address", "");
      setValue("email", "");
      setValue("userName", "");
      setValue("faxNumber", "");
      setValue("email", "");
      setValue("taxID", "");
      setValue("phoneNumber", "");
      setValue("indemnityLetter", "");
      setValue("companyTypeOpc", "");
      clearErrors([
        "companyType",
        "faxNumber",
        "companyName",
        "address",
        "email",
        "userName",
        "taxID",
        "phoneNumber",
        "indemnityLetter",
        "companyTypeOpc",
      ]);
      // Clear uploaded documents
      dispatch(clearRegistrationFiles());

      dispatch(
        getCompanyTypes({
          partnerCountry: countrywatch.substring(0, 2),
        })
      )
        .unwrap()
        .then((data) => {
          if (
            data?.length > 0 &&
            !location.pathname.includes("master") &&
            countrywatch !== "TZ"
          )
            setValue("companyType", [data[0].value]);
          else if (!location.pathname.includes("master"))
            setValue("companyType", []);
          if (
            data?.length > 0 &&
            !location.pathname.includes("master") &&
            countrywatch == "TZ"
          )
            setValue("companyTypeOpc", "");
          else if (!location.pathname.includes("master"))
            setValue("companyTypeOpc", "");
        });
      if (countrywatch == "TZ") {
        dispatch(
          getSeaPortTypes({
            partnerCountry: countrywatch?.substring(0, 2),
          })
        )
          .unwrap()
          .then((data: any) => {
            if (
              data?.length > 0 /* && !location.pathname.includes("master") */
            ) {
              setValue("seaPort", data[0].value);
            } else if (location.pathname.includes("master"))
              setValue("seaPort", "");
          });
        dispatch(
          getPortOperatorTypes({
            partnerCountry: countrywatch?.substring(0, 2),
          })
        )
          .unwrap()
          .then((data: any) => {
            if (data?.length > 0 /* && !location.pathname.includes("master") */)
              setValue("portOperator", data[0]?.value);
            else if (location.pathname.includes("master"))
              setValue("portOperator", []);
          });
        dispatch(
          getShippingLineTypes({
            partnerCountry: countrywatch.substring(0, 2),
          })
        )
          .unwrap()
          .then((data: any) => {
            if (data?.length > 0 && !location.pathname.includes("master")) {
              /*   if (watch("country") === "TZ") {
                setValue("shippingLines", []);
              } else {
                setValue("shippingLines", data[1].value);
              }*/
            }
          });
      }
      /*  if (!location.pathname.includes("/registrationmaster")) {
        dispatch(
          getRegistrationTaxDocConfig({ country: countrywatch.substring(0, 2) })
        );
      } */
    }
  }, [countrywatch, dispatch, setValue]);

  useEffect(() => {
    if (location.pathname.includes("/registrationmaster"))
      dispatch(updateRegistrationMaster(true));
  }, []);
  const [isChecked, setIsChecked] = useState(countrywatch === "TZ");

  /*  const handleCheckboxChange = (label: string, checked: boolean, id: any) => {
    if (checked) {
      setSelectedOptions([
        ...selectedOptions,
        { mstGrpId: id, usrGrpNm: label },
      ]);
      setIsUserGroupSelected(selectedOptions.length > 0 || checked);
      setIsUserGroupSelectedTZ(checked);
    } else {
      setSelectedOptions(
        selectedOptions.filter((option: any) => option.mstGrpId !== id)
      );
    }
  }; */
  const handleCheckboxChange = (label: string, checked: boolean, id: any) => {
    // Update checked items
    setCheckedItems((prev) => ({
      ...prev,
      [id]: checked,
    }));

    // Compute updated options immediately
    const updatedOptions = checked
      ? [...selectedOptions, { mstGrpId: id, usrGrpNm: label }]
      : selectedOptions.filter((option: any) => option.mstGrpId !== id);

    // Compute flags immediately
    const isSelected = updatedOptions.length > 0;
    const isSelectedTZ = countrywatch === "TZ" && isSelected;

    // Update states based on immediate calculations
    setSelectedOptions(updatedOptions);
    setIsUserGroupSelected(isSelected);
    setIsUserGroupSelectedTZ(isSelectedTZ);

    // Log for debugging
  };

  const Option = (props: any) => (
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
      <label>{props.label}</label>
    </components.Option>
  );

  const onSubmit = handleSubmit(
    async (submissionPayload) => {
      /*   if (!termsAccepted) {
        setTermsError(true);
        return; // Prevent form submission
      } */
      // localStorage.removeItem("formData");
      // Check if the Tax ID is valid before proceeding

      // Check if the indemnity file is uploaded for TZ country
      if (countrywatch === "TZ" && !isIndemnityFileUploaded) {
        dispatch(
          updateIndemnityDocument({
            error: "Please upload the indemnity document",
          })
        );
        return; // Prevent form submission
      }

      // Clear the error if the file is uploaded
      if (indemnityDocument.error) {
        dispatch(updateIndemnityDocument({ error: null }));
      }

      if (taxidvalidval === true && watch("country") === "TZ") {
        setError("taxID", {
          type: "manual",
          message: "Tax ID already present",
        });
        return; // Prevent form submission
      }

      dispatch(validationCheckRegistration());
      try {
        submissionPayload.address = watch("address");
        const type: string = countrywatch === "TZ" ? "opc" : "Odex";

        const companyTypePayload =
          countrywatch === "TZ"
            ? [Number(submissionPayload.companyTypeOpc)]
            : submissionPayload.companyType;

        const Payload = {
          ...submissionPayload,
          companyType: companyTypePayload,
          loiJson: localStorage.getItem("formData"),
          type: type,
          linerIdForCustomerMapping: linerIdForCustomerMapping,
        };




        dispatch(
          registerCustomerAndUser({
            ...Payload,
            emptyYardCode: watch('companyType').map((x) => `${x}`).includes('4') ? watch('companyName') : null
          })
        )
          .unwrap()
          .then((response: any) => {
            if (!response?.data) return;
            setResponseCodeValue(response.data.responseCode);
            if (
              response.data.responseCode === "CREATE" ||
              response.data.responseCode === "EXIST/CREATE"
            ) {
              reset();
              countrywatch == "TZ"
                ? /*  setRedirectUrl("opc/home"): setRedirectUrl("opc/ome") */
                (window.location.href = "/opc/home")
                : (window.location.href = "/home");
              setRenderMessage(t("resource_12:registerSuccessfull"));
              if (countrywatch === "TZ") {
                localStorage.removeItem("formData");
              }

              handleShowMessage();
              //toast.dark(response.data.responseMsg);
            } else if (response.data.responseCode === "MANUAL") {
              setRenderMessage(response.data.responseMsg);
              /*  if (countrywatch === "TZ") {
                localStorage.removeItem("formData");
              }
 */
              handleShowMessage();
              // Redirect based on environment
              const redirectUrl = process.env.REACT_APP_API_GATEWAY_HOST
                ? process.env.REACT_APP_API_GATEWAY_HOST
                : "";
              setRedirectUrl(redirectUrl);
            } else {
              toast.dark(response.data.responseMsg);
              /*   if (countrywatch === "TZ") {
                localStorage.removeItem("formData");
              }
 */
              setRedirectUrl("");
              setRenderMessage(response.data.responseMsg);
              handleShowMessage();
            }
          });
      } catch (err: any) {
        console.error(err);
      }
    },
    (error) => {
      console.error("Handle submit error: ", error);
      dispatch(validationCheckRegistration());
    }
  );

  const registrationAddressSuggesstion = useSelector(
    (state: RootState) => state.registration.registrationAddressSuggesstion
  );

  const isAddressSuggesstionLoading = useSelector(
    (state: RootState) => state.registration.isAddressSuggesstionsLoading
  );

  const selectedAddressResult = useSelector(
    (state: RootState) => state.registration.selectedAddressResult
  );

  useEffect(() => {
    dispatch(updateSelectedCountry(watch("country")));
  }, [watch("country")]);

  const _onAddressChange = useAsyncDebounce((value: string) => {
    dispatch(
      fetchAddressSuggestions({
        text: value,
        country:
          countryOptions.find((i: any) => i.value === countrywatch).full ??
          "USA",
      })
    );
  }, 100);

  const [show, setShow] = useState(false);
  const [showLetter, setShowLetter] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPolicy, setShowPolicy] = useState(false);

  const handleClosePolicy = () => {
    setShowPolicy(false);
    setShowLetter(false);
  };
  const handleShowPolicy = () => setShowPolicy(true);
  const [responseCodeValue, setResponseCodeValue] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [renderMessage, setRenderMessage] = useState("");
  const handleCloseMessage = () => {
    if (responseCodeValue == "MANUAL") {
      setShowMessage(false);
      navigate(`/`);
    } else if (responseCodeValue == "CREATE") {
      setShowMessage(false);
      countrywatch == "TZ"
        ? /*  setRedirectUrl("opc/home"): setRedirectUrl("opc/ome") */
        (window.location.href = "/opc/home")
        : (window.location.href = "/home");
      //  window.location.href = `/home`;
    } else {
      if (redirectUrl !== "") {
        setShowMessage(false);
        countrywatch == "TZ"
          ? /*  setRedirectUrl("opc/home"): setRedirectUrl("opc/ome") */
          (window.location.href = "/opc/home")
          : (window.location.href = "/home");
        // window.location.href = `/home`;
      } else {
        setShowMessage(false);
      }
    }
  };
  const handleShowMessage = () => setShowMessage(true);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [placeId, setPlaceId] = useState("");
  const emailwatch = watch("email");

  useEffect(() => {
    if (
      (!countrywatch || countrywatch === "") &&
      countryOptions?.length > 0 &&
      !location.pathname.includes("registrationmaster")
    ) {
      setValue("country", countryOptions[0].value);
    }
  }, [countryOptions]);
  useEffect(() => {
    if (countrywatch) {
      i18next.changeLanguage(countrywatch.substring(0, 2));
    }
  }, [emailwatch, countrywatch]);
  const prevCountryRef = useRef<string | null>(null);

  useEffect(() => {
    if (
      decrptrequestId != "" /* && prevCountryRef.current !== countrywatch */
    ) {
      dispatch(
        getRegistrationDetails({
          decrptrequestId,
        })
      )
        .unwrap()
        .then((response: any) => {
          setRegistrationDetails({
            countryCode: response.data.countryCode,
            customerAddress: response.data.customerAddress,
            userName: response.data.userName,
            seaPort: response.data.port,
            companyName: response.data.customerName,
            companyTypeOpc: response.data.companyIdList,
            taxID: response.data.customerTaxId,
            //email: response.data.email,
            contactNo: response.data.contactNo,
            partnerId: response.data.partnerId,
            shippingLines: response.data.shippingLines,
            portOperator: response.data.terminals,
            opcPartnerType: response.data.opcPartnerType,
            email: response.data.email,
            faxNumber: response.data.faxNo,
            loiJsonString: response.data.loiJsonString,
            language: response.data.language,
            customerMapping: response.data.customerMapping,
            isTNCAccepted: response.data.isTNCAccepted,
            mstGrp: response.data.mstGrp,
            requestId: response.data.requestId,
            mstUsrId: response.data.mstUsrId,
            remarks: response.data.remarks,
            status: response.data.status,
            //emptyYardCode:response.data.emptyYardCode,
            cfsCode: response.data.cfsCode,

            indemnityLetter: response.data.loiName,
            isloiapproved: response.data.isloiapproved,
            isIdentityNoApproved: response.data.isIdentityNoApproved,
          });

          setValue("email", response.data.email);
          setValue("country", response.data.countryCode);
          setValue("companyType", response.data.companyIdList);
          //  setValue("companyName", response.data.customerName);
          setValue("userName", response.data.customerName);
          setValue("taxID", response.data.customerTaxId);
          setValue("faxNumber", response.data.faxNo);
          //   setValue("userName", response.data.userName);
          setValue("phoneNumber", response.data.contactNo);
          setValue("customerCode", response.data.customerMapping?.[0]?.mapCode);
          setValue("liner", response.data.customerMapping?.[0]?.linerId);
          setValue("companyType", response.data.companyIdList);
          setValue("country", response.data.countryCode);
          setValue("shippingLines", response.data.shippingLines);
          setValue("indemnityLetter", response.data.loiName);
          setValue("isloiapproved", response.data.isloiapproved);
          setValue("acceptCondition", response.data.acceptCondition);
          setValue("isIdentityNoApproved", response.data.isIdentityNoApproved);

          // setValue("portOperator", response.data.terminals);
          // Set portOperator value
        });
    }
  }, [
    decrptrequestId,
    countrywatch,
    // countrywatch,
    //  portOperatorOptions,
    //seaPortOptions,
  ]);
  useEffect(() => {
    if (countrywatch == "TZ") {
      const terminalIds = Array.isArray(registrationDetails?.portOperator)
        ? registrationDetails.portOperator.map((terminal: any) =>
          terminal.terminalId?.toString()
        )
        : [];
      if (terminalIds.length > 0) {
        setValue("portOperator", terminalIds, { shouldValidate: true });

        if (portOperatorOptions.length > 0) {
          const portOperatorTexts: any = terminalIds
            .map(
              (id: any) =>
                portOperatorOptions.find((item: any) => item.value === id)
                  ?.text || null
            )
            .filter(Boolean);

          setPortOperatorText(portOperatorTexts);
        } else {
          setValue("portOperator", [], { shouldValidate: false });
          setPortOperatorText([]);
        }
      }
      const shippingLineIds = Array.isArray(registrationDetails.shippingLines)
        ? registrationDetails.shippingLines.map((terminal: any) =>
          terminal?.shippingLineId?.toString()
        )
        : registrationDetails.shippingLines
          ? [registrationDetails.shippingLines.toString()]
          : [];
      if (shippingLineIds.length > 0) {
        setValue("shippingLines", shippingLineIds, {
          shouldValidate: true,
        });

        const shippiliLineTexts: any = shippingLineIds
          .map(
            (id: string) =>
              shippingLineOptions.find((item: any) => String(item.value) === id)
                ?.text || null
          )
          .filter(Boolean);

        setShippingLineText(shippiliLineTexts);
      } else {
        setValue("shippingLines", [], { shouldValidate: false });
        setShippingLineText([]);
      }
      //companyType
      const companyTypeIds =
        [registrationDetails.companyTypeOpc].map((id: any) => String(id)) || [];
      if (companyTypeIds.length > 0) {
        const companyTypetexts: any = companyTypeIds
          .map(
            (id: string) =>
              companyTypeOptions?.find((item: any) => String(item.value) === id)
                ?.text || null
          )
          .filter(Boolean);

        setCompanyTypeText(companyTypetexts);
      } else {
        setCompanyTypeText([]);
      }

      const seaportCode =
        registrationDetails?.seaPort &&
          typeof registrationDetails?.seaPort === "object"
          ? (
            registrationDetails?.seaPort as { portCode?: string }
          ).portCode?.toString() || "" // Assert as object with portCode
          : registrationDetails?.seaPort?.toString() || ""; // If it's a string, use directly;

      if (seaportCode) {
        setValue("seaPort", seaportCode, { shouldValidate: true });

        // Find the corresponding seaPortText based on the seaportCode
        if (seaPortOptions?.length > 0) {
          const seaportText =
            seaPortOptions?.find(
              (item: any) => String(item.value) === seaportCode
            )?.text || "";

          setSeaportTxt(seaportText);
        } else {
          setValue("seaPort", "", { shouldValidate: false });
          setSeaportTxt("");
        }
      }
    }

    if (
      countrywatch !== "TZ" &&
      countrywatch !== undefined &&
      location.pathname.includes("master")
    ) {
      dispatch(getGroupListRegistrationMaster(countrywatch))
        .unwrap()
        .then((response: any) => {
          setGroupList(
            response.map((v: any) => {
              return { label: v?.usrGrpNm, value: v?.mstGrpId };
            })
          );
        });
    }

    if (countrywatch == "TZ" && location.pathname.includes("master")) {
      dispatch(
        getGroupListRegistrationMasterOPC({
          countryCode: watch("country"),
          partnerType: registrationDetails?.opcPartnerType,
        })
      )
        .unwrap()
        .then((response: any) => {
          setGroupList(
            response.map((v: any) => {
              return { label: v?.usrGrpNm, value: v?.mstGrpId };
            })
          );
        });
    }
  }, [
    registrationDetails,
    countrywatch,
    setValue,
    dispatch,
    countryOptions,
    portOperatorOptions,
    // companyTypeOptions,
    seaPortOptions,
    //shippingLineOptions,

    //dispatch,
    //seaPortOptions,
    /*     countrywatch,
    setValue,
    dispatch, */
  ]);
  const [faxnumber, setfaxnumber] = useState(""); // Add this line to handle fax number state
  const [showStatus, setShowStatus] = useState(false);
  const handleCloseStatus = () => setShowStatus(false);
  const handleShowStatus = () => setShowStatus(true);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(e.target.checked);
    if (e.target.checked) setTermsError(false); // Remove error if checked
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        {location.pathname.split("/")[1] !== "registrationmaster" && (
          <h3 className="fs-1 mb-0">{t("resource_1:registration")}</h3>
        )}
        {/*   {watch("country") == "TZ" && */}
        {!location.pathname.includes("registrationmaster") && (
          <a
            href="#"
            onClick={handleShowStatus}
            className="ms-auto text-primary text-decoration-none fw-bold"
            style={{ fontSize: "0.9rem" }}
          >
            Track Request
          </a>
        )}
        <Modal show={showStatus} onHide={handleCloseStatus}>
          <Modal.Header className="p-2 pb-1" closeButton>
            <Modal.Title
              style={{
                fontSize: "1.2rem",
                fontWeight: "normal",
                paddingLeft: "20px",
              }}
            >
              Track Request
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflow: "auto", fontSize: "12px" }}>
            <CheckStatus />
          </Modal.Body>
        </Modal>
      </div>

      {location.pathname.split("/")[2] !== "registrationmaster" && (
        <Divider></Divider>
      )}

      <div className="h2 text-secondary fs-0 pb-2 mt-3 border-bottom">
        {t("resource_2:companyDetails")}
      </div>
      <Form onSubmit={onSubmit} autoComplete="off">
        <div className="row mt-2">
          <div className="row mt-2 align-items-end">
            <div className="col-lg-4 mb-2">
              <Form.Group controlId="registration.country">
                <Form.Label className="fs--1">
                  <span className="mandatory">*</span>
                  {t("resource_1:country")}
                </Form.Label>

                <Form.Select
                  {...register("country")}
                  className={`fs--1 form-control ${errors.country ? "is-invalid" : ""
                    }`}
                  value={
                    registrationDetails.countryCode != ""
                      ? registrationDetails.countryCode
                      : countrywatch
                  }
                  disabled={registrationMasterDisable}
                  onChange={(e) => {
                    setValue("country", e.target.value);
                  }}
                >
                  {countryOptions.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                  {registrationMasterDisable && (
                    <option value={registrationDetails.countryCode}>
                      {registrationDetails.countryCode != ""
                        ? registrationDetails.countryCode
                        : countrywatch}
                    </option>
                  )}
                </Form.Select>
                <div className="invalid-feedback">
                  {errors.country?.message?.toString()}
                </div>
              </Form.Group>
            </div>
            {/*          {country === "TZ" && (
              <div
                className="col-lg-4 mb-0"
                style={{ maxHeight: "100px", position: "relative" }}
              >
                <label
                  className="align-items-center fs--1 text-secondary"
                  style={{
                    border: "1px solid #ced4da",
                    padding: "5px 12px",
                    borderRadius: "4px",
                    backgroundColor: "#f8f9fa",
                    cursor: "pointer",
                    color: "#000",
                    height: "35px",
                    width: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  Register For Port Services
                  <div
                    style={{
                      position: "absolute",
                      top: "-10px",
                      right: "-10px",

                      borderRadius: "50%",
                      padding: "5px",
                      height: "20px",
                      width: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "grey",
                      fontSize: "12px",
                    }}
                  >
                    <InfoDiv title="info">
                      <i className="fa fa-info" aria-hidden="true"></i>{" "}
                    </InfoDiv>
                  </div>
                </label>
              </div>
            )} */}
          </div>
        </div>

        <div className="row">
          {countrywatch === "TZ" && (
            <>
              <div className="col-md-6 mb-2">
                <Form.Group controlId="registration.seaPort">
                  <Form.Label className="fs--1">
                    <span className="mandatory">*</span>
                    {t("resource_2:seaPort")}
                  </Form.Label>
                  {!registrationMasterDisable && (
                    <Form.Select
                      {...register("seaPort", { required: true })}
                      className={`fs--1 form-control ${errors.seaPort ? "is-invalid" : ""
                        }`}
                      disabled={registrationMasterDisable}
                      value={
                        registrationMasterDisable
                          ? seaportTxt
                          : watch("seaPort")
                      }
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        const selectedValue = e.target.value;
                        setValue("seaPort", selectedValue, {
                          shouldValidate: true,
                        });

                        /*   const seaportText =
                        seaPortOptions.find(
                          (item: any) => String(item.value) === selectedValue
                        )?.text || "";

                      setSeaportTxt(seaportText); */

                      }}
                    >
                      {seaPortOptions?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                  <div className="invalid-feedback">
                    {errors.seaPort?.message || "Sea Port is required."}
                  </div>
                </Form.Group>
                {registrationMasterDisable && (
                  <div>
                    <Form.Control
                      disabled={true}
                      value={seaportTxt}
                      size="sm"
                      /*        {...register('vesselVoyage')}
                        className={`fs--1 form-control form-control-sm ${
                          errors.vesselVoyage ? 'is-invalid' : ''
                        }`} */
                      type="text"
                      maxLength={20}
                    />
                  </div>
                )}
              </div>

              {countrywatch == "TZ" && (
                <div className="col-lg-4 mb-2">
                  <Form.Group controlId="registration.portOperator">
                    <Form.Label
                      className="fs--1"
                      style={{ display: "inline", whiteSpace: "nowrap" }}
                    >
                      <span
                        className="mandatory"
                        style={{ marginRight: "5px" }}
                      >
                        *
                      </span>
                      {t("resource_2:portOperator")}
                    </Form.Label>
                    <Select
                      {...register("portOperator")}
                      components={{
                        MultiValueRemove: CustomMultiValueRemove,
                        ClearIndicator: CustomClearIndicator,
                      }}
                      onChange={(selectedOptions: any) => {
                        const defaultOption = portOperatorOptions[0]; // Assuming the first option is the default
                        let values = selectedOptions
                          ? selectedOptions.map((i: any) => i.value)
                          : [];

                        // Ensure the default option is always included
                        if (!values.includes(defaultOption.value)) {
                          values = [defaultOption.value, ...values];
                        }

                        setValue("portOperator", values);

                        setPortOperatorText(
                          selectedOptions.map((option: any) => option.label)
                        );
                      }}
                      options={portOperatorOptions?.map((item: any) => ({
                        value: item.value,
                        label: item.text,
                      }))}
                      className={`fs--1 ${errors.portOperator?.toString ? "is-invalid" : ""
                        }`}
                      classNames={{
                        control: (state) => `fs--1 m-0 p-0 asyncDropField
            ${state.isDisabled ? "" : "dropControl form-control"}
            ${state.isFocused ? "focusField" : ""}`,
                      }}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          fontSize: "16px",
                          padding: "0 10px",
                          minHeight: "auto",
                          height: "auto",
                          maxHeight: "100%",
                          border: state.isFocused
                            ? "1px solid #007bff"
                            : "1px solid #ced4da",
                          boxShadow: state.isFocused
                            ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                            : "none",
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        dropdownIndicator: (base, state) =>
                          state.isDisabled ? { display: "none" } : { ...base },
                      }}
                      isDisabled={location?.pathname?.includes("master")}
                      noOptionsMessage={() => "No group"}
                      isMulti={true}
                      isSearchable={true}
                      value={(() => {
                        if (registrationMasterDisable) {
                          // For registration master view, use portOperatorText
                          return portOperatorText.map((text) => ({
                            value:
                              portOperatorOptions.find(
                                (option: { text: any }) => option.text === text
                              )?.value || "",
                            label: text,
                          }));
                        } else {
                          // For normal view, use the existing logic
                          const portOperator = watch("portOperator");
                          if (!portOperator || portOperator.length === 0)
                            return [];
                          return portOperatorOptions
                            .filter((item: any) =>
                              portOperator.includes(item.value)
                            )
                            .map((item: any) => ({
                              value: item.value,
                              label: item.text,
                            }));
                        }
                      })()}
                    />
                    <div className="invalid-feedback">
                      {errors.portOperator?.message ||
                        (Array.isArray(errors.portOperator) &&
                          errors.portOperator.length > 0
                          ? errors.portOperator[0]
                          : "Port / Terminal Operator is required.")}
                    </div>
                  </Form.Group>
                </div>
              )}
            </>
          )}
        </div>

        <div className="row mt-2">
          <div className="col-lg-4 mb-2">
            <Form.Group controlId="registration.taxID">
              <Form.Label className="fs--1">
                <span className="mandatory">*</span>
                {t("resource_2:taxId")}
              </Form.Label>
              <Form.Control
                type="text"
                value={
                  registrationDetails.taxID != ""
                    ? registrationDetails.taxID
                    : watch("taxID")
                }
                placeholder={t("resource_2:taxId")}
                {...register("taxID")}
                className={`fs--1 form-control ${errors.taxID ? "is-invalid" : ""
                  }`}
                /*  onChange={({ target }) => {
                  setValue("taxID", target.value.trim());
                  // _onTaxIdChange(target.value);
                }} */
                onBlur={async (e) => {
                  if (watch("country") === "TZ") {
                    const taxIDValue = e.target.value.trim();
                    setValue("taxID", taxIDValue);
                    clearErrors("taxID");

                    if (taxIDValue) {
                      try {
                        const response = await dispatch(
                          taxIDValidation(taxIDValue)
                        ).unwrap();

                        setTaxIdValidVal(response);

                        if (response === true) {
                          setError("taxID", {
                            type: "manual",
                            message: "Tax ID already present",
                          });
                        } else {
                          clearErrors("taxID");
                        }
                      } catch (error) {
                        console.error("Tax ID validation failed:", error);
                        setError("taxID", {
                          type: "manual",
                          message: "Failed to validate Tax ID",
                        });
                      }
                    } else {
                      setError("taxID", {
                        type: "manual",
                        message: "Tax ID is required",
                      });
                    }
                  }
                }}
                onChange={({ target }) => {
                  if (countrywatch === "TZ") {
                    let formattedValue = target.value
                      .replace(/\D/g, "") // Remove any non-digit character
                      .replace(/(\d{3})(?=\d)/g, "$1-"); // Add hyphen every 3 digits

                    setValue("taxID", formattedValue);
                  } else {
                    setValue("taxID", target.value.trim());
                  }
                }}
                disabled={
                  registrationMasterDisable /* && registrationDetails.status != 2 */
                }
              /*  onBlur={(e) => {
              if (watch("taxID") !== "") {
                setValue("taxID", e.target.value);
                clearErrors("taxID");
              }
            }} */
              />
              <div className="invalid-feedback">
                {errors.taxID?.message?.toString()}
              </div>
            </Form.Group>

            <>
              {registrationdata.taxId?.data?.reg_number !== undefined &&
                registrationdata.taxId?.data?.reg_number === watch("taxID") && (
                  <span className="mt-2 text-primary fs--1 fw-bold">
                    <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                    <span>{t("resource_4:companyNoVerifiedSuccessfully")}</span>
                  </span>
                )}
            </>
          </div>

          <div className="col-lg-3 mb-2">
            {(!location.pathname.includes("registrationmaster") ||
              (taxDocument.name && taxDocument.name !== "")) && (
                <Form.Label className="fs--1">
                  {taxDocument.m && <span className="mandatory">*</span>}
                  {t("resource_2:taxId") + " Document"}
                </Form.Label>
              )}
            {location.pathname.includes("registrationmaster") ? (
              <div className="asyncDropField d-flex align-items-center">
                <span
                  className="fs--1 file-name"
                  style={{
                    color: "#2c7be5",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "300px", // Adjust width as needed
                  }}
                  title={taxDocument.name} // Tooltip with full name on hover
                  onClick={() => {
                    window.open(
                      `/api/user-management/v1/customer/openDocument?id=${decrptrequestId}&moduleTp=IDENTITY&linerId=${linerId}`
                    );
                  }}
                >
                  {taxDocument.name}
                </span>
              </div>
            ) : (
              <UploadThing
                name={taxDocument.name}
                loading={taxDocument.loading}
                labelWidth={"12vw"}
                invalid={taxDocument.error && !isFileUploaded}
                fileLimit={5}
                fileType={countrywatch === "TZ" ? ["PDF", "JPG", "JPEG", "PNG", "DOC", "DOCX"] : ["PDF"]}
                fulfilled={(name, serverPath, serverName) => {
                  dispatch(
                    updateTaxDocument({
                      name: name,
                      serverPath: serverPath,
                      serverName: serverName,
                      loading: 100,
                    })
                  );
                  setIsFileUploaded(true);
                }}
                pending={(loading) => {
                  dispatch(updateTaxDocument({ loading: loading }));
                }}
                selected={(name) => {
                  dispatch(updateTaxDocument({ name: name }));
                  setIsFileUploaded(true);
                }}
                clear={() => {
                  dispatch(
                    updateTaxDocument({
                      name: "",
                      serverPath: null,
                      serverName: null,
                      loading: 0,
                    })
                  );
                  setIsFileUploaded(false);
                }}
                failed={() => {
                  dispatch(updateTaxDocument({ loading: -1 }));
                  setIsFileUploaded(false);
                }}
              />
            )}
          </div>
          {location.pathname.includes("opc/registrationmaster") && (
            <div className="col-sm-1 mb-2">
              <Form.Group>
                <Form.Label>Verify</Form.Label>
                <div>
                  <Form.Check
                    type="checkbox"
                    id="verifyTaxDocument"
                    onChange={(e) => {
                      setIsTaxDocumentVerified(e.target.checked ? "Y" : "N");
                    }}
                    disabled={
                      registrationMasterDisable &&
                      registrationDetails.status != 2
                    }
                    checked={isTaxDocumentVerified === "Y"}
                  />
                </div>
              </Form.Group>
            </div>
          )}

          <div className="col-lg-6 mb-2">
            <Form.Group controlId="registration.companyName">
              <Form.Label className="fs--1">
                <span className="mandatory">*</span>
                {t("resource_2:companyName")}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t("resource_1:company")}
                {...register("companyName")}
                className={`fs--1 form-control ${errors.companyName ? "is-invalid" : ""
                  }`}
                value={
                  registrationDetails?.companyName != ""
                    ? registrationDetails?.companyName
                    : watch("companyName")
                }
                /*   disabled={
                   registrationMasterDisable &&  registrationDetails?.status !==
                  2 
                } */
                disabled={registrationMasterDisable}
                onChange={(e) => {
                  setValue("companyName", e.target.value);
                  clearErrors("companyName");
                }}
              />

              <div className="invalid-feedback">
                {errors.companyName?.message?.toString()}
              </div>
            </Form.Group>
          </div>

          {(!location?.pathname?.includes("master") ||
            watch("companyType")?.length > 0) &&
            companyTypeOptions?.length > 0 &&
            countrywatch !== "TZ" && (
              <div className="col-lg-6 mb-2">
                <Form.Group controlId="registration.companyType">
                  <Form.Label className="fs--1">
                    <span className="mandatory">*</span>
                    {t("resource_2:companyType")}
                  </Form.Label>
                  <Select
                    {...register("companyType")}
                    onChange={(u: any) => {
                      const value = u.map((i: any) => i.value)
                      setValue(
                        "companyType",
                        value
                      );

                      if (value.includes('3') || value.includes('4')) {
                        dispatch(resetPartenerTable())
                      }
                      //setValue('companyType', e.target.value)
                      // setMstGrp(u.map((v: any) => v));
                      // setValue(
                      //   'mstGrp',
                      //   u.map((v: any) => v)
                      // );
                    }}
                    options={companyTypeOptions?.map((item: any) => ({
                      value: item.value,
                      label: item.text,
                    }))}
                    className={`fs--1 ${errors.companyType?.toString ? "is-invalid" : ""
                      }`}
                    classNames={{
                      control: (state) => {
                        return `fs--1 m-0 p-0 asyncDropField
                  ${state.isDisabled ? "" : "dropControl form-control"}
                  ${state.isFocused ? "focusField" : ""}`;
                      },
                    }}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      control: (base) => ({}),
                      dropdownIndicator: (base, state) =>
                        state.isDisabled ? { display: "none" } : { ...base },
                    }}
                    isDisabled={location?.pathname?.includes("master")}
                    noOptionsMessage={() => "No group"}
                    isMulti={true}
                    autoFocus={true}
                    isSearchable={true}
                    value={companyTypeOptions
                      ?.filter((item: any) =>
                        watch("companyType")?.includes(item.value)
                      )
                      .map((item: any) => ({
                        value: item.value,
                        label: item.text,
                      }))}
                  />

                  <div className="invalid-feedback">
                    {errors.companyType?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
            )}


          {/* { watch('companyType')?.includes(4)? <>
              <div className='col-lg-6 mb-2' >
            <Form.Group controlId='registration.emptyYardCode'>
              <Form.Label className='fs--1'>
                <span className='mandatory'>*</span>
                {t('resource_3:emptyYardCode')}
              </Form.Label>
              <Form.Control
                type='text'
                placeholder={t('resource_3:emptyYardCode')}
                {...register('emptyYardCode')}
                className={`fs--1 form-control ${
                  errors.emptyYardCode ? 'is-invalid' : ''
                }`}
                value={emptyYardCode}
                disabled={false}
                onChange={(e) => {
                  setEmptyYardCode(e.target.value);
                }}
              /> 
             <div className='invalid-feedback'>
                {errors.emptyYardCode?.message?.toString()}
              </div>
            </Form.Group>
          </div>
          </> : "" } */}
          {watch('companyType')?.includes(3) ? <>
            <div className='col-lg-6 mb-2' >
              <Form.Group controlId='registration.cfsCode'>
                <Form.Label className='fs--1'>
                  <span className='mandatory'>*</span>
                  {t('resource_2:cfsCode')}
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder={t('resource_2:cfsCode')}
                  {...register('cfsCode')}
                  className={`fs--1 form-control ${errors.cfsCode ? 'is-invalid' : ''
                    }`}
                  value={cfsCode}
                  disabled={false}
                  onChange={(e) => {
                    setCfsCode(e.target.value);
                  }}
                />
                <div className='invalid-feedback'>
                  {errors.cfsCode?.message?.toString()}
                </div>
              </Form.Group>
            </div>
            {/*  <div className="col-lg-6 mb-2">
           <div id="tags" className=" fs--1 form-control  form-control">
            <ReactTags
              tags={watch("cfsCode")}
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              inputFieldPosition="bottom"
              autocomplete
              allowDragDrop={false}
            />
           
          </div>
           <span style={{color:'#e63757',marginTop:'0.25%',fontSize:'75%'}}>{errors.cfsCode?.message?.toString()} </span>
          </div> */}
          </> : ""}

          {!location?.pathname?.includes("master") /* ||
            watch("companyTypeOpc")?.length > 0 */ &&
            countrywatch == "TZ" &&
            companyTypeOptions?.length > 0 && (
              <div className="flex-shrink-0" style={{ width: "300px" }}>
                <Form.Group>
                  <Form.Label>
                    <span className="mandatory">*</span>
                    {t("resource_2:companyType")}
                  </Form.Label>
                  <Form.Select
                    {...register("companyTypeOpc")}
                    onChange={(e: any) => {
                      setValue("companyTypeOpc", e.target.value);
                      clearErrors("companyTypeOpc");
                    }}
                    value={
                      registrationDetails?.companyTypeOpc != ""
                        ? registrationDetails?.companyTypeOpc
                        : watch("companyTypeOpc")
                    }
                    className={`fs--1 ${errors?.companyTypeOpc ? "is-invalid" : ""
                      }`}
                  >
                    <option value="">{t("resource_1:select")}</option>
                    {companyTypeOptions?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Form.Select>
                  <div className="invalid-feedback">
                    {errors.companyTypeOpc?.message ||
                      t("resource_2:companyTypeRequired")}
                  </div>
                </Form.Group>
              </div>
            )}
          {registrationMasterDisable && countrywatch === "TZ" && (
            <div className="col-lg-6 mb-2">
              <Form.Group controlId="registration.companyType">
                <Form.Label className="fs--1">
                  <span className="mandatory">*</span>
                  {t("resource_2:companyType")}
                </Form.Label>

                <div>
                  <Form.Control
                    disabled={true}
                    value={companyTypeText}
                    size="sm"
                    /*        {...register('vesselVoyage')}
                        className={`fs--1 form-control form-control-sm ${
                          errors.vesselVoyage ? 'is-invalid' : ''
                        }`} */
                    type="text"
                    maxLength={20}
                  />
                </div>
              </Form.Group>
            </div>
          )}

          {countrywatch === "TZ" &&
            [watch("companyTypeOpc")]?.includes("12") && (
              <div className="col-lg-6 mb-2">
                <Form.Group controlId="registration.shippingLines">
                  <Form.Label className="fs--1">
                    <span className="mandatory">*</span>
                    {t("resource_2:shippingLines")}
                  </Form.Label>

                  {!registrationMasterDisable && countrywatch === "TZ" && (
                    <CreatableSelect
                      {...register("shippingLines")}
                      isMulti
                      options={shippingLineOptions?.map((item: any) => ({
                        value: item.value,
                        label: item.text,
                      }))}
                      onChange={(selectedOptions: any) => {
                        const values = selectedOptions
                          ? selectedOptions.map((option: any) => option.value)
                          : [];

                        setValue("shippingLines", values);

                        clearErrors("shippingLines");
                      }}
                      className={`fs--1 form-control form-control-sm ${errors.shippingLines?.toString ? "is-invalid" : ""
                        }`}
                      styles={customDropdownStyles}
                      components={{ Option }}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      isDisabled={location?.pathname?.includes("master")}
                      noOptionsMessage={() => "No Shipping Lines"}
                      autoFocus={true}
                      placeholder="Select the Shipping Line you are managing"
                      isSearchable={true}
                      value={(() => {
                        const shippingLines =
                          registrationDetails.shippingLines != ""
                            ? registrationDetails.shippingLines
                            : watch("shippingLines");
                        if (!shippingLines) return [];
                        const values = Array.isArray(shippingLines)
                          ? shippingLines
                          : [shippingLines];
                        return shippingLineOptions
                          .filter((item: any) => values.includes(item.value))
                          .map((item: any) => ({
                            value: item.value,
                            label: item.text,
                          }));
                      })()}
                    />
                  )}

                  <div className="invalid-feedback">
                    {errors.shippingLines?.message ||
                      "Shipping Lines are required."}
                  </div>
                </Form.Group>
              </div>
            )}
          {registrationDetails?.shippingLines?.length > 0 &&
            countrywatch === "TZ" && (
              /*  [watch("companyTypeOpc")]?.includes("12") &&  */ <div className="col-lg-12 mb-2">
                <Form.Group controlId="registration.shippingLines">
                  <Form.Label className="fs--1">
                    <span className="mandatory">*</span>
                    {t("resource_2:shippingLines")}
                  </Form.Label>

                  <div>
                    <Form.Control
                      disabled={true}
                      value={shippingLineText}
                      className={`fs--1 form-control`}
                      // size="sm"
                      /*        {...register('vesselVoyage')}
                        className={`fs--1 form-control form-control-sm ${
                          errors.vesselVoyage ? 'is-invalid' : ''
                        }`} */

                      type="text"
                      maxLength={20}
                    />
                  </div>

                  <div className="invalid-feedback">
                    {errors.shippingLines?.message ||
                      "Shipping Lines are required."}
                  </div>
                </Form.Group>
              </div>
            )}
          <div className="col-lg-12 mb-2">
            <div className="d-flex flex-column justify-content-center align-items-start odex-position-relative w-100">
              <Form.Group
                controlId="registration.address.address"
                className="w-100"
              >
                <Form.Label className="fs--1">
                  <span className="mandatory">*</span>
                  {t("resource_1:address")}
                </Form.Label>
                <Form.Control
                  type="textarea"
                  value={
                    registrationDetails.customerAddress != ""
                      ? registrationDetails.customerAddress
                      : watch("address")
                  }
                  placeholder={t("resource_1:address")}
                  {...register("address")}
                  className={`fs--1 form-control ${errors.address ? "is-invalid" : ""
                    }`}
                  as="textarea"
                  onChange={({ target }) => {
                    dispatch(updateSelectedAddressResult(""));
                    setValue("address", target.value);
                    clearErrors("address");
                    _onAddressChange(target.value);
                    if (target.value == "") {
                      setPlaceId("");
                    }
                  }}
                  disabled={registrationMasterDisable}
                  /*      value={
                     registrationDetails.customerAddress != ""
                      ? registrationDetails.customerAddress
                      :  address
                  } */
                  rows={1}
                />
                <div className="invalid-feedback">
                  {errors.address?.message?.toString()}
                </div>
              </Form.Group>

              {watch("address") &&
                !selectedAddressResult &&
                !isAddressSuggesstionLoading &&
                !placeId &&
                registrationAddressSuggesstion?.length > 0 ? (
                <div className="rounded border bg-white w-100 odex-position-absolute odex-registration-address d-flex flex-column justify-content-start align-items-start">
                  {registrationAddressSuggesstion.map((v: any) => (
                    <div
                      className="p-2 d-flex flex-row justify-content-start align-items-center fs--1 cursor-pointer"
                      key={v.placeId}
                      onClick={() => {
                        dispatch(updateSelectedAddressResult(v.text));
                        setValue("address", v.text);
                        setPlaceId(v.placeId);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faLocation}
                        className="text-primary me-2"
                      />
                      <span>{v.text}</span>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {countrywatch === "TZ" && (
            <div className="col-lg-12 mb-2">
              {/*  <div className="d-flex align-items-start mt-1"> */}
              <div className="row d-flex flex-wrap align-items-start mt-1">
                {/* Text Input Field */}
                <Form.Group
                  controlId="registration.indemnityLetter"
                  /*  className="me-3" */
                  className="col-12 col-md-6 col-lg-4 mb-2"
                >
                  <Form.Label className="fs--1">
                    {t("resource_2:indemnityLetter")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={
                      registrationDetails.indemnityLetter
                        ? registrationDetails.indemnityLetter
                        : watch("indemnityLetter")
                    }
                    // style={{ width: "400px" }}
                    placeholder="Indemnity Letter"
                    {...register("indemnityLetter")}
                    className={`fs--1 form-control ${errors.indemnityLetter ? "is-invalid" : ""
                      }`}
                    onChange={({ target }) => {
                      setValue("indemnityLetter", target.value);

                      clearErrors("indemnityLetter");
                    }}
                    disabled={
                      registrationMasterDisable /* &&
                      registrationDetails.status != 2 */
                    }
                    onBlur={(e) => {
                      if (watch("indemnityLetter") !== "") {
                        setValue("indemnityLetter", e.target.value.trim());
                      }
                    }}
                  />
                  <div className="invalid-feedback">
                    {errors.indemnityLetter?.message?.toString()}
                  </div>
                </Form.Group>

                {/* Upload Field */}
                {/*                 <div className="col-lg-3 mb-2"> */}
                <div className="col-12 col-md-6 col-lg-4 mb-2">
                  {/* Added margin-start to space it from text field */}
                  {(!location.pathname.includes("registrationmaster") ||
                    (indemnityDocument.name &&
                      indemnityDocument.name !== "")) && (
                      <Form.Label className="fs--1">
                        {indemnityDocument.m && (
                          <span className="mandatory">*</span>
                        )}
                        {t("resource_2:indemnityLetter") + " Document"}
                        {""}
                      </Form.Label>
                    )}

                  {location.pathname.includes("registrationmaster") ? (
                    <div className="asyncDropField d-flex align-items-center">
                      <span
                        className="fs--1 file-name"
                        style={{
                          color: "#2c7be5",
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          // maxWidth: "150px", // Adjust width as needed
                          maxWidth: "100%",
                        }}
                        title={indemnityDocument.name} // Tooltip with full name on hover
                        onClick={() => {
                          window.open(
                            `/api/user-management/v1/customer/openDocument?id=${decrptrequestId}&moduleTp=IDENTITY&linerId=${linerId}`
                          );
                        }}
                      >
                        {indemnityDocument.name}
                      </span>
                    </div>
                  ) : (
                    <div
                      style={{ pointerEvents: isSubmitted ? "auto" : "none" }}
                    >
                      <UploadThing
                        name={indemnityDocument.name}
                        loading={indemnityDocument.loading}
                        /* labelWidth={"12vw"} */
                        labelWidth={"100%"}
                        disabled={!isSubmitted}
                        invalid={
                          indemnityDocument.error && !isIndemnityFileUploaded
                        }
                        fileLimit={5}
                        fileType={["PDF"]}
                        fulfilled={(name, serverPath, serverName) => {
                          dispatch(
                            updateIndemnityDocument({
                              name: name,
                              serverPath: serverPath,
                              serverName: serverName,
                              loading: 100,
                            })
                          );
                          setIsIndemnityFileUploaded(true);
                        }}
                        pending={(loading) => {
                          dispatch(
                            updateIndemnityDocument({ loading: loading })
                          );
                        }}
                        selected={(name) => {
                          dispatch(updateIndemnityDocument({ name: name }));
                          setIsIndemnityFileUploaded(true);
                        }}
                        clear={() => {
                          dispatch(
                            updateIndemnityDocument({
                              name: "",
                              serverPath: null,
                              serverName: null,
                              loading: 0,
                            })
                          );
                          setIsIndemnityFileUploaded(false);
                        }}
                        failed={() => {
                          dispatch(updateIndemnityDocument({ loading: -1 }));
                          setIsIndemnityFileUploaded(false);
                        }}
                      />
                    </div>
                  )}
                </div>
                {location.pathname.includes("opc/registrationmaster") && (
                  /*   <div className="col-sm-1 mb-2"> */
                  <div className="col-12 col-md-2 mb-2">
                    <Form.Group>
                      <Form.Label>Verify</Form.Label>
                      <div>
                        <Form.Check
                          type="checkbox"
                          id="verifyIndemnityDocument"
                          onChange={(e) => {
                            setIsIndemnityDocumentVerified(
                              e.target.checked ? "Y" : "N"
                            );
                          }}
                          disabled={
                            registrationMasterDisable &&
                            registrationDetails.status != 2
                          }
                          checked={isIndemnityDocumentVerified === "Y"}
                        />
                      </div>
                    </Form.Group>
                  </div>
                )}
              </div>

              {!location.pathname.includes("registrationmaster") && (
                <small className="form-text text-muted mt-1">
                  (
                  <Link onClick={() => setShowLetter(true)} to={""}>
                    Click Here
                  </Link>
                  &nbsp;to download the system-generated indemnity letter with
                  necessary details for finalizing and uploading the signed and
                  stamped document)
                </small>
              )}
              {location.pathname.includes("registrationmaster") && (
                <small className="form-text text-muted mt-1">
                  (
                  <Link onClick={() => setShowLetter(true)} to={""}>
                    Click Here
                  </Link>
                  &nbsp;to view the indemnity letter )
                </small>
              )}
            </div>
          )}
          {/*   {countrywatch !== "TZ" ||
            (location.pathname.includes("registrationmaster") && (
              <RegistrationDocumentTable
                country={countrywatch}
                isDisabled={location.pathname.includes("registrationmaster")}
                companyType={watch("companyType")}
              />
            ))} */}
          {/*  {(countrywatch == "TZ" && watch("companyTypeOpc")) ||
            (location.pathname.includes("registrationmaster") && ( */}
          {/*   {!location.pathname.includes("registrationmaster") && (
            <RegistrationDocumentTable
              country={countrywatch}
              isDisabled={location.pathname.includes("registrationmaster")}
              companyType={
                countrywatch == "TZ"
                  ? watch("companyTypeOpc")
                  : watch("companyType")
              }
              status={registrationDetails.status}
            />
          )}

          {location.pathname.includes("registrationmaster") &&
            countrywatch == "TZ" && (
              <RegistrationDocumentTable
                country={countrywatch}
                isDisabled={location.pathname.includes("registrationmaster")}
                companyType={registrationDetails.companyTypeOpc[0]}
                status={registrationDetails.status}
              />
            )}
          {location.pathname.includes("registrationmaster") &&
            countrywatch !== "TZ" && (
              <RegistrationDocumentTable
                country={watch("country")}
                isDisabled={location.pathname.includes("registrationmaster")}
                companyType={watch("companyType")}
              />
            )}
 */}

          {countrywatch &&
            (
              <>
                {
                  countrywatch == 'TZ' ?
                    (
                      <RegistrationDocumentTableTz
                        country={countrywatch}
                        isDisabled={location.pathname.includes("registrationmaster")}
                        companyType={
                          location.pathname.includes("registrationmaster")
                            ? countrywatch === "TZ"
                              ? registrationDetails.companyTypeOpc[0]
                              : watch("companyType")
                            : countrywatch === "TZ"
                              ? watch("companyTypeOpc")
                              : watch("companyType")
                        }
                        status={registrationDetails.status}
                      />
                    ) :
                    <RegistrationDocumentTable
                      country={countrywatch}
                      isDisabled={location.pathname.includes("registrationmaster")}
                      companyType={
                        location.pathname.includes("registrationmaster")
                          ? countrywatch === "TZ"
                            ? registrationDetails.companyTypeOpc[0]
                            : watch("companyType")
                          : countrywatch === "TZ"
                            ? watch("companyTypeOpc")
                            : watch("companyType")
                      }
                      status={registrationDetails.status}
                    />

                }
              </>
            )
          }

          {countrywatch == "TZ" &&
            (registrationDetails.status == 1 ||
              registrationDetails.status == 3) && (
              <RegistrationPartnerTable
                country={countrywatch}
                isDisabled={registrationMasterDisable}
                disableAttach={location.pathname.includes("registrationmaster")}
                requestId={decrptrequestId}
              />
            )}

          {countrywatch !== "TZ" && (!watch('companyType')?.includes(3) && !watch('companyType')?.includes(4)) && (
            <RegistrationPartnerTable
              country={countrywatch}
              isDisabled={
                registrationMasterDisable && registrationDetails.status != 2
              }
              disableAttach={location.pathname.includes("registrationmaster")}
              requestId={decrptrequestId}
            />
          )}

          {/*     ))} */}

          <div className="h2 text-secondary fs-0 pb-1 border-bottom">
            {t("resource_2:contactDetails")}
          </div>
          <div className="row mt-1">
            <div className="col-lg-3 mb-2">
              <Form.Group controlId="registration.companyName">
                <Form.Label className="fs--1">
                  <span className="mandatory">*</span>
                  {t("resource_1:primary_contact")}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={
                    registrationDetails.userName != ""
                      ? registrationDetails.userName
                      : watch("userName")
                  }
                  disabled={registrationMasterDisable}
                  placeholder={t("resource_1:primary_contact")}
                  {...register("userName")}
                  onChange={({ target }) => {
                    setValue("userName", target.value);
                    clearErrors("userName");
                  }}
                  className={`fs--1 form-control ${errors.userName ? "is-invalid" : ""
                    }`}
                />
                <div className="invalid-feedback">
                  {errors.userName?.message?.toString()}
                </div>
              </Form.Group>
            </div>
            <div className="col-lg-3 mb-2">
              <Form.Group controlId="registration.email">
                <Form.Label className="fs--1">
                  <span className="mandatory">*</span>
                  {t("resource_1:email")}
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={
                    registrationMasterDisable && registrationDetails.status != 2
                  }
                  placeholder={t("resource_1:email")}
                  {...register("email")}
                  value={emailwatch}
                  className={`fs--1 form-control ${errors.email ? "is-invalid" : ""
                    }`}
                  onChange={(e) => {
                    setValue("email", e.target.value);
                    clearErrors("email");
                  }}
                />
                <div className="invalid-feedback">
                  {errors.email?.message?.toString()}
                </div>
              </Form.Group>
            </div>

            <div className="col-lg-3 mb-2">
              <Form.Group controlId="registration.company.phoneNo">
                <Form.Label className="fs--1">
                  {countrywatch == 'TZ' && <span className="mandatory">*</span>}
                  {t("resource_2:phoneNo")}
                </Form.Label>

                <Form.Control
                  type="text"
                  disabled={registrationMasterDisable}
                  placeholder={t("resource_2:phoneNo")}
                  {...register("phoneNumber")}
                  /*   onChange={({ target }) => {
                    setValue("phoneNumber", target.value);
                  }} */
                  onChange={({ target }) => {
                    const numericValue = target.value.replace(/[^0-9+]/g, ""); // Remove non-numeric characters
                    setValue("phoneNumber", numericValue);
                    clearErrors("phoneNumber");
                  }}
                  value={
                    registrationDetails.contactNo != ""
                      ? registrationDetails.contactNo
                      : watch("phoneNumber")
                  }
                  className={`fs--1 form-control ${errors.phoneNumber && countrywatch === "TZ"
                    ? "is-invalid"
                    : ""
                    }`}
                />
                {countrywatch == "TZ" && (
                  <div className="invalid-feedback">
                    {errors.phoneNumber?.message?.toString()}
                  </div>
                )}
              </Form.Group>
            </div>
            {countrywatch === "TZ" && (
              <div className="col-lg-3 mb-2">
                <Form.Group controlId="registration.company.faxNumber">
                  <Form.Label className="fs--1">{t("Fax Number")}</Form.Label>
                  <Form.Control
                    type="text"
                    disabled={registrationMasterDisable}
                    placeholder={t("Fax Number")}
                    {...register("faxNumber")}
                    /*  onChange={({ target }) => {
                      setValue("faxNumber", target.value);
                    }} */
                    onChange={({ target }) => {
                      const numericValue = target.value.replace(/[^0-9+]/g, ""); // Remove non-numeric characters
                      setValue("faxNumber", numericValue);
                      clearErrors("faxNumber");
                    }}
                    value={
                      registrationDetails.faxNumber != ""
                        ? registrationDetails.faxNumber
                        : watch("faxNumber")
                    }
                    className={`fs--1 form-control ${errors.faxNumber ? "is-invalid" : ""
                      }`}
                  />

                  <div className="invalid-feedback">
                    {errors.faxNumber?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
            )}

            {registrationMasterDisable && countrywatch == "TZ" && (
              <div className="col-lg-12 mb-2 mt-2">
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    id="registration.acceptCondition"
                    feedback="invalid"
                    label={
                      <div>
                        <span>{t("resource_3:iAcceptThe")} </span>
                        <Link onClick={handleShow} to={""}>
                          {t("resource_1:terms").toLowerCase()}
                        </Link>
                        <span> {t("resource_1:and").toLowerCase()} </span>
                        <Link
                          className="fs--1 mb-0 link"
                          to={""}
                          onClick={handleShowPolicy}
                        >
                          {t("resource_2:privacyPolicy").toLowerCase()}
                        </Link>
                      </div>
                    }
                    /*      {...register("acceptCondition", {
                      required: "Please accept the terms and privacy policy.",
                    })} */
                    //className={`${errors.acceptCondition ? "is-invalid" : ""}`}
                    /* onChange={(e) => {
                      setValue("acceptCondition", e.target.checked);

                    }} */
                    checked={registrationDetails.isTNCAccepted == "Y"}
                    disabled={registrationMasterDisable}
                  />
                  {/*  {errors.acceptCondition && (
                    <div className="text-danger">
                      {errors.acceptCondition.message?.toString()}
                    </div>
                  )} */}
                </Form.Group>
              </div>
            )}
            {registrationMasterDisable && (
              <div className="col-lg-12 mb-2 mt-2">
                <Form.Label className="h2 text-secondary fs-0 pb-1 border-bottom">
                  {t("resource_2:usr_grp")}
                </Form.Label>
                {groupList.map((groupList: any, index: any) => (
                  <>
                    {registrationDetails.status != 2 ? (
                      <Form.Check
                        key={groupList.value}
                        type="checkbox"
                        id={groupList.label}
                        label={groupList.label}
                        disabled={
                          registrationDetails.status != 2 ? true : false
                        }
                        checked={
                          registrationDetails.status == 2 ||
                            registrationDetails.mstGrp.find(
                              (item: any) =>
                                item.mstGrpId ==
                                groupList.value /* || groupList */
                            )
                            ? true
                            : false
                        }
                      />
                    ) : (
                      <Form.Check
                        key={groupList.value}
                        type="checkbox"
                        id={groupList.label}
                        label={groupList.label}
                        checked={
                          countrywatch === "TZ"
                            ? checkedItems[groupList.value] !== undefined
                              ? checkedItems[groupList.value]
                              : true
                            : checkedItems[groupList.value] || false
                        }
                        onChange={(e) => {
                          const checked = e.target.checked;
                          handleCheckboxChange(
                            groupList.label,
                            checked,
                            groupList.value
                          ); // Update checkbox state based on user interaction
                        }}
                      />
                    )}
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
        {!registrationMasterDisable && (
          <Form.Group>
            <Form.Check
              type="checkbox"
              id="registration.acceptTerms"
              feedback="invalid"
              label={
                <div>
                  <span>{t("resource_3:iAcceptThe")} </span>
                  <Link onClick={handleShow} to={""}>
                    {t("resource_1:terms").toLowerCase()}
                  </Link>
                  <span> {t("resource_1:and").toLowerCase()} </span>
                  <Link
                    className="fs--1 mb-0 link"
                    to={""}
                    onClick={handleShowPolicy}
                  >
                    {t("resource_2:privacyPolicy").toLowerCase()}
                  </Link>
                </div>
              }
              {...register("acceptTerms")}
              className={`${errors.acceptTerms ? "is-invalid" : ""}`}
              onBlur={(e) => {
                setValue("acceptTerms", e.target.checked);
              }}
            />
            <div className="invalid-feedback">
              {errors.acceptTerms?.message?.toString()}
            </div>
          </Form.Group>
        )}
        {!registrationMasterDisable && (
          <div className="row">
            <div className="col-4" />
            <div className="col-4">
              <Form.Group className="d-flex justify-content-center align-items-center">
                <button
                  type="submit"
                  className={`custom-button mt-0 mb-2 w-100 ${listloading ? "loadingPulse" : ""
                    }`}
                  disabled={listloading}
                >
                  <span style={{ fontSize: "13px" }}>
                    {t("resource_1:register")}
                  </span>
                </button>
              </Form.Group>
            </div>
            <div className="col-4" />
          </div>
        )}
        {registrationMasterDisable && (
          <div className="col-lg-6 mb-2">
            <Form.Group controlId="reqForm.remarks">
              <Form.Label>{t("resource_1:remark")}</Form.Label>
              <Form.Control
                as="textarea"
                disabled={registrationDetails.status != 2 ? true : false}
                // value={remarks}
                value={
                  registrationDetails.remarks != "" &&
                    registrationDetails.status != 2
                    ? registrationDetails.remarks
                    : remarks
                }
                onChange={({ target }) => {
                  setremarks(target.value);
                }}
              //  value={state.customerRemarks}

              //disabled={!!matchPth}
              />
            </Form.Group>
          </div>
        )}
        {registrationMasterDisable && registrationDetails.status == 2 && (
          <div className="d-flex justify-content-center align-items-center">
            <Button
              onClick={(e) => {
                /*   if (!watch("acceptCondition") && countrywatch == "TZ") {
                  toast.dark("Please accept the terms and privacy policy");
                  return;
                } */

                /*     if (selectedOptions.length > 0) { */
                // onApproveReject('A');

                const payload = {
                  //requestId: decrptrequestId,
                  requestType: 1,

                  countryCode: registrationDetails.countryCode,
                  customerTaxId: watch("taxID"),
                  customerName: registrationDetails.companyName,
                  customerAddress: registrationDetails?.customerAddress,
                  userName: watch("userName"),
                  customerMapping: registrationDetails.customerMapping,
                  contactNo: watch("phoneNumber"),
                  mstGrp: selectedOptions,
                  email: watch("email"),
                  requestId: decrptrequestId,
                  mstUsrId: registrationDetails.mstUsrId,
                  remarks: remarks,
                  faxNo: watch("faxNumber") ? watch("faxNumber") : "",
                  companyIdList: watch("companyType"),

                  loiJson: registrationDetails.loiJsonString,
                  isTNCAccepted: registrationDetails.isTNCAccepted ? "Y" : "N",
                };

                const isSelected = selectedOptions.length > 0;
                const isSelectedTZ = countrywatch === "TZ" && isSelected;

                // Check conditions with derived flags
                if (!isSelected && countrywatch !== "TZ") {
                  toast.dark(t("resource_message:userGroupMandatory"));
                  return;
                }

                if (!isSelectedTZ && countrywatch === "TZ") {
                  toast.dark(t("resource_message:userGroupMandatory"));
                  return;
                }
                if (taxDocument.isVerificationReq && countrywatch === "TZ") {
                  if (isTaxDocumentVerified !== "Y") {
                    toast.error("Verification of Tax Document is required");
                    return;
                  }
                }

                if (
                  indemnityDocument.isVerificationReq == true &&
                  countrywatch === "TZ" &&
                  isIndemnityDocumentVerified !== "Y"
                ) {
                  toast.error("Verification of Indemnity Document is required");
                  return;
                }
                const type: string = countrywatch === "TZ" ? "opc" : "Odex";
                const Payloadnew = {
                  ...payload,
                  shippingLines: registrationDetails.shippingLines,
                  port: registrationDetails.seaPort,
                  terminals: registrationDetails.portOperator,
                  loiJsonString: registrationDetails.loiJsonString,
                  type: type,
                  partnerId: registrationDetails.partnerId,
                  opcPartnerType: registrationDetails.opcPartnerType,
                };
                const verifyTax = isTaxDocumentVerified;
                const verifyIndeminity = isIndemnityDocumentVerified;
                dispatch(
                  approveRejectRegistration({
                    Payloadnew,
                    documentOptions,
                    verifyTax,
                    verifyIndeminity,
                    indemnityDocument,
                    taxDocument,
                    isUserGroupSelected,
                    isUserGroupSelectedTZ,
                  })
                )
                  .unwrap()
                  .then((data) => {
                    if (data.responseCode === "APPROVED") {
                      setTimeout(() => {
                        setTimeout(() => {
                          dispatch(updateDashbaordRefresh(true));
                        }, 2000);
                      }, 500);
                      navigate(-1);
                    }
                  });

                /*      }  else {
                  toast.dark(t("resource_message:userGroupMandatory"));
                }  */
              }}
              variant="success"
              className="me-4 approve"
              size="sm"
            >
              <FontAwesomeIcon icon={faCheck} className="me-2 approve" />
              {t("resource_1:approve")}
            </Button>

            <Button
              variant="danger"
              className="reject"
              size="sm"
              onClick={(e) => {
                // if (!watch("acceptCondition")) {
                //   toast.dark("Accept the Terms First.");
                //   return;
                // }

                // if (!isUserGroupSelected) {
                //   toast.dark(t("resource_message:userGroupMandatory"));
                //   return;
                // }
                // onApproveReject('R');

                //   if (selectedOptions.length > 0) {
                if (remarks == "") {
                  toast.dark(t("resource_message:remarksMandatory"));
                } else {
                  /* const payload = {
                  
                    requestType: 3,

                    countryCode: registrationDetails.countryCode,
                    mstGrp: selectedOptions,
                    email: emailwatch,
                    requestId: decrptrequestId,
                    mstUsrId: registrationDetails.mstUsrId,
                    remarks: remarks,

                    customerTaxId: watch("taxID"),
                    customerName: watch("companyName"),
                    customerAddress: registrationDetails?.customerAddress,
                    userName: registrationDetails?.userName,
                    contactNo: registrationDetails?.contactNo,
                    customerMapping: watch("customerCode")
                      ? [
                          {
                            linerId:
                              registrationDetails?.customerMapping[0].linerId,
                            mapCode: watch("customerCode"),
                          },
                        ]
                      : [],
                  }; */
                  const payload = {
                    //requestId: decrptrequestId,
                    requestType: 3,

                    countryCode: registrationDetails.countryCode,
                    customerTaxId: watch("taxID"),
                    customerName: registrationDetails.companyName,
                    opcPartnerType: registrationDetails.opcPartnerType,
                    customerAddress: registrationDetails?.customerAddress,
                    userName: watch("userName"),
                    customerMapping: registrationDetails.customerMapping,
                    contactNo: watch("phoneNumber"),
                    mstGrp: selectedOptions,
                    email: watch("email"),
                    requestId: decrptrequestId,
                    mstUsrId: registrationDetails.mstUsrId,
                    remarks: remarks,
                    faxNo: watch("faxNumber") ? watch("faxNumber") : "",
                    companyIdList: watch("companyType"),

                    loiJson: registrationDetails.loiJsonString,
                    isTNCAccepted: registrationDetails.isTNCAccepted
                      ? "Y"
                      : "N",
                  };
                  const type: string = countrywatch === "TZ" ? "opc" : "Odex";
                  const Payloadnew = {
                    ...payload,
                    shippingLines: registrationDetails.shippingLines,
                    port: registrationDetails.seaPort,
                    terminals: registrationDetails.portOperator,
                    loiJsonString: registrationDetails.loiJsonString,
                    type: type,
                    partnerId: registrationDetails.partnerId,
                  };
                  const verifyTax = isTaxDocumentVerified;
                  const verifyIndeminity = isIndemnityDocumentVerified;
                  dispatch(
                    approveRejectRegistration({
                      Payloadnew,
                      documentOptions,
                      verifyTax,
                      verifyIndeminity,
                    })
                  )
                    .unwrap()
                    .then((data) => {
                      // if (data?.length > 0)
                      if (data.responseCode === "REJECTED")
                        setTimeout(() => {
                          navigate(-1);
                          dispatch(updateDashbaordRefresh(true));
                        }, 1000);
                    });
                }
                //  } /*  else {
                //    toast.dark(t('resource_message:userGroupMandatory'));
                //  }
              }}
            >
              <FontAwesomeIcon icon={faTimes} className="me-2 reject" />
              {t("resource_1:reject")}
            </Button>
          </div>
        )}
      </Form>
      {
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Header className="p-2 pb-1" closeButton>
            <Modal.Title>{t("resource_3:termsAndConditions")}</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              height: "500px",
              overflow: "auto",
              fontSize: "12px",
            }}
          >
            <TermsAndConditions />
          </Modal.Body>
        </Modal>
      }

      {
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Header className="p-2 pb-1" closeButton>
            <Modal.Title>{t("resource_3:termsAndConditions")}</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              height: "500px",
              overflow: "auto",
              fontSize: "12px",
            }}
          >
            <TermsAndConditions />
          </Modal.Body>
        </Modal>
      }
      {
        <Modal show={showPolicy} onHide={handleClosePolicy} size="xl">
          <Modal.Header className="p-2 pb-1" closeButton>
            <Modal.Title>{t("resource_2:privacyPolicy")}</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              height: "500px",
              overflow: "auto",
              fontSize: "12px",
            }}
          >
            <PrivacyPolicy />
          </Modal.Body>
        </Modal>
      }
      {
        <Modal show={showLetter} onHide={handleClosePolicy} size="xl">
          <Modal.Header className="p-2 pb-1" closeButton>
            <Modal.Title>
              Letter of Indemnity for Release of Container from TEAGTL (TPA 2)
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              height: "500px",
              overflow: "auto",
              fontSize: "12px",
            }}
          >
            <IndemnityLetter decrptrequestId={decrptrequestId} />
          </Modal.Body>
        </Modal>
      }
      {
        <Modal
          show={showMessage}
          //onHide={handleCloseMessage}
          size="lg"
        >
          <Modal.Body
            className="text-center"
            style={{
              height: "auto",
              overflow: "auto",
              fontSize: "12px",
            }}
          >
            <div className="fs-1 text-secondary">{renderMessage}</div>
          </Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              className="me-2 btn btn-falcon-primary btn-sm"
              onClick={handleCloseMessage}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </div>
  );
};
export default Registration;
