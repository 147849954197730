export const Home = (data) => {
  return {
    label: 'Dashboard',
    labelDisable: true,
    children: [
      data?.rbacDetails?.blDashboard === 'Y' && {
        name: 'BL Dashboard',
        icon: 'chart-pie',
        to: '/home',
        active: true,
      },
      data?.rbacDetails?.invDashboard === 'Y' && {
        name: 'Invoice Dashboard',
        to: '/payment',
        active: false,
        icon: 'shopping-cart',
      },
      data?.rbacDetails?.instructionDashboard === 'Y' && {
        name: 'Instruction',
        to: '/instruction',
        active: true,
        icon: 'book',
      },
      data?.rbacDetails?.registrationMstDashboard === 'Y' && {
        name: 'Registration Master',
        to: '/registrationmaster',
        active: true,
        icon: 'file-pen',
      },
      (data?.rbacDetails?.userMgmtCreate === 'Y' ||
        data?.rbacDetails?.userMgmtView === 'Y') && {
        name: 'Users',
        active: false,
        icon: 'user',
        to: '/users',
      },
      data?.rbacDetails?.payDetailsDashboard === 'Y' && {
        name: 'Payment Details',
        to: '/paymentDashboard',
        active: false,
        icon: 'pay',
      },
      data?.rbacDetails?.paymentDashboard === 'Y' && {
        name: 'Transactions',
        to: '/payment-fund',
        active: false,
        icon: 'credit-card',
      },
      data?.rbacDetails?.standingInstDashboard === 'Y' && {
        name: 'Standing Instruction',
        to: '/standing',
        active: false,
        icon: 'list-ul',
      },
      data?.rbacDetails?.reportDashboard === 'Y' && {
        name: 'Report',
        to: '/report',
        active: false,
        icon: 'report',
      },
      data?.rbacDetails?.receiptDashboard === 'Y' && {
        name: 'Receipt',
        to: '/receipt',
        active: false,
        icon: 'receipt',
      },
      data?.rbacDetails?.mappingDashboard === 'Y' && {
        name: 'Customer Mapping',
        to: '/mappingdashboard',
        active: false,
        icon: 'user-plus',
      },
       {
        name: 'Gate In-Out',
        to: '/gateinout',
        active: false,
        icon: 'gateinout',
      }
    ].filter((i) => i),
  };
};

const getRoutes = (data) => {
  return [Home(data)];
};

export default getRoutes;
