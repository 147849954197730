import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { setIsSubmitted } from "./registraionSlice";
import {
  getIntemnityLetterPdf,
  getRegistrationDetails,
} from "./actions/Registration";
import { toast } from "react-toastify";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TermsAndConditions from "./termsAndCondition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IndemnityLetter = ({ decrptrequestId }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const isSubmitted = useSelector(
    (state: RootState) => state.registration.isSubmitted
  );
  const [form, setForm] = useState({
    companyTin: "",
    companyName: "",
    directorName: "",
    directorEmail: "",
    directorMobile: "",
    adminName: "",
    adminEmail: "",
    adminMobile: "",
    isAccepted: "",
  });
  const labelStyle: any = {
    width: "180px", // Fixed width for consistent alignment
    textAlign: "left",
    paddingRight: "10px",
    whiteSpace: "nowrap",
  };
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);
  const inputStyle = {
    width: "200px", // Uniform width across all inputs
    height: "38px", // Uniform height for all input fields
    padding: "6px 12px", // Padding within the input field
  };
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const handleShow = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShow(true);
  };

  const handleClose = () => setShow(false);
  const errorStyle: React.CSSProperties = {
    color: "red",
    fontSize: "12px",
    marginLeft: "10px", // Space between input and error message
    width: "150px", // Fixed width for error messages to prevent pushing
    textAlign: "left", // Align text to the left
  };

  const [errors, setErrors] = useState({
    companyTin: "",
    companyName: "",
    directorName: "",
    directorEmail: "",
    directorMobile: "",
    adminName: "",
    adminEmail: "",
    adminMobile: "",
    isAccepted: "",
  });
  useEffect(() => {
    const storedData = localStorage.getItem("formData");
    if (storedData) {
      setForm(JSON.parse(storedData)); // Set the form state with the saved data
    }
  }, []);
  useEffect(() => {
    console.log("decrptrequestId123", decrptrequestId);
    if (decrptrequestId != "") {
      /*     const response = {
        countryCode: "TZ",
        customerTaxId: "21345678",
        taxByteArr: null,
        taxFileName: "blank.pdf",
        taxFilePath:
          "odex-warehouse-documents/customer/identity/2024/10/20241030074006_blank.pdf",
        taxServerFilePath: null,
        taxTempFileName: null,
        customerName: "dgfdgdfgdfg",
        customerAddress:
          "Asia Street, Dar es Salaam, Kinondoni, Dar es Salaam, 61325, TZA",
        userName: "dfgdf",
        email: "asda@ghvjgh.ssdf",
        contactNo: "34234",
        language: "EN_US",
        customerMapping: [
          {
            linerId: 29990,
            mapCode: "21345678",
            byteArr: null,
            fileName: null,
            filePath: null,
            serverFilePath: null,
            tempFileName: null,
          },
        ],
        isTNCAccepted: "Y",
        mstGrp: [],
        requestId: 354,
        partnerId: 40746,
        mstUsrId: 1938,
        remarks: null,
        status: 2,
        companyIdList: [12],
        customerDocumentInputs: [
          {
            partnerDocumentId: 195,
            mstPartnerId: 354,
            docId: 416,
            docNo: "",
            issueDate: "2024-09-30",
            validTillDate: null,
            filePath:
              "odex-warehouse-documents/customer/document/2024/10/20241030074053_blank.pdf",
            fileName: "blank.pdf",
            byteArr: null,
            serverFilePath: null,
            tempFileName: null,
            isApproved: null,
          },
          {
            partnerDocumentId: 196,
            mstPartnerId: 354,
            docId: 402,
            docNo: "",
            issueDate: "2024-09-30",
            validTillDate: null,
            filePath:
              "odex-warehouse-documents/customer/document/2024/10/20241030074055_blank.pdf",
            fileName: "blank.pdf",
            byteArr: null,
            serverFilePath: null,
            tempFileName: null,
            isApproved: null,
          },
          {
            partnerDocumentId: 197,
            mstPartnerId: 354,
            docId: 413,
            docNo: "",
            issueDate: "2024-09-30",
            validTillDate: null,
            filePath:
              "odex-warehouse-documents/customer/document/2024/10/20241030074057_blank.pdf",
            fileName: "blank.pdf",
            byteArr: null,
            serverFilePath: null,
            tempFileName: null,
            isApproved: null,
          },
          {
            partnerDocumentId: 198,
            mstPartnerId: 354,
            docId: 414,
            docNo: "",
            issueDate: "2024-09-30",
            validTillDate: "2024-10-30",
            filePath:
              "odex-warehouse-documents/customer/document/2024/10/20241030074101_blank.pdf",
            fileName: "blank.pdf",
            byteArr: null,
            serverFilePath: null,
            tempFileName: null,
            isApproved: null,
          },
          {
            partnerDocumentId: 199,
            mstPartnerId: 354,
            docId: 415,
            docNo: "",
            issueDate: "2024-09-30",
            validTillDate: null,
            filePath:
              "odex-warehouse-documents/customer/document/2024/10/20241030074103_blank.pdf",
            fileName: "blank.pdf",
            byteArr: null,
            serverFilePath: null,
            tempFileName: null,
            isApproved: null,
          },
        ],
        port: {
          mstPartnerPortId: null,
          mstPartnerId: 40746,
          portCode: 3940,
        },
        terminals: [
          {
            mstPartnerTerminalId: null,
            terminalId: 1,
            mstPartnerId: 40746,
          },
        ],
        shippingLines: [
          {
            custShippingLineMapId: null,
            terminalId: 1,
            customerId: 40746,
            shippingLineId: 2,
          },
        ],
        loiServerFilePath: null,
        loiTempFileName: null,
        loiFileName: "20241030074046_blank.pdf",
        loiFilePath:
          "odex-warehouse-documents/customer/loi/2024/10/20241030074046_blank.pdf",
        loiJson: null,
        loiJsonString:
          '{"isAccepted":null,"companyTin":null,"companyName":"gfdhfgh","directorName":"dsfsdf","directorEmail":null,"directorMobile":null,"adminName":"dfhdfhd","adminEmail":"asda@ghvjgh.ssdf","adminMobile":"34567890"}',
        isloiapproved: null,
        isIdentityNoApproved: null,
        type: null,
        faxNo: "",
        navisPayeeIdRequest: null,
        navisPayeeIdResponse: null,
      }; */
      dispatch(
        getRegistrationDetails({
          decrptrequestId,
        })
      )
        .unwrap()
        .then((response: any) => {
          console.log("indemnityhere", response.data.loiJsonString);

          // Parse the loiJsonString
          const loiData = JSON.parse(response.data.loiJsonString);

          // Set the form values
          setForm({
            companyTin: loiData.companyTin,
            companyName: loiData.companyName,
            directorName: loiData.directorName,
            directorEmail: loiData.directorEmail,
            directorMobile: loiData.directorMobile,
            adminName: loiData.adminName,
            adminEmail: loiData.adminEmail,
            adminMobile: loiData.adminMobile,
            isAccepted: loiData.isAccepted,
          });
        });
    }
  }, [decrptrequestId]);
  const validateForm = () => {
    let valid = true;
    let newErrors = { ...errors };
    const nameRegex = /^[A-Za-z\s]+$/;
    // Company TIN validation (required, numeric)
    if (!form.companyTin) {
      newErrors.companyTin = "Company TIN is required";
      valid = false;
    } /* else if (isNaN(Number(form.companyTin))) {
      newErrors.companyTin = "Company TIN must be a number";
      valid = false;
    }  */ else {
      newErrors.companyTin = "";
    }

    // Company Name validation (required)
    if (!form.companyName) {
      newErrors.companyName = "Company Name is required";
      valid = false;
    } else if (!nameRegex.test(form.companyName)) {
      newErrors.companyName = "Company Name must contain only alphabets";
      valid = false;
    } else {
      newErrors.companyName = "";
    }

    // Director Name validation (required)
    if (!form.directorName) {
      newErrors.directorName = "Director Name is required";
      valid = false;
    } else if (!nameRegex.test(form.directorName)) {
      newErrors.directorName = "Director Name must contain only alphabets";
      valid = false;
    } else {
      newErrors.directorName = "";
    }
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!form.directorEmail) {
      newErrors.directorEmail = "Email is required";
      valid = false;
    } else if (!emailRegex.test(form.directorEmail)) {
      newErrors.directorEmail = "Invalid email format";
      valid = false;
    } else {
      newErrors.directorEmail = "";
    }

    // Mobile validation (required, numeric)
    if (!form.directorMobile) {
      newErrors.directorMobile = "Mobile number is required";
      valid = false;
    } else if (isNaN(Number(form.directorMobile))) {
      newErrors.directorMobile = "Mobile number must be numeric";
      valid = false;
    } else {
      newErrors.directorMobile = "";
    }

    // Admin Name validation (required)
    if (!form.adminName) {
      newErrors.adminName = "Administrator Name is required";
      valid = false;
    } else if (!nameRegex.test(form.adminName)) {
      newErrors.adminName = "Administrator Name must contain only alphabets";
      valid = false;
    } else {
      newErrors.adminName = "";
    }

    // Admin Email validation
    if (!form.adminEmail) {
      newErrors.adminEmail = "Administrator Email is required";
      valid = false;
    } else if (!emailRegex.test(form.adminEmail)) {
      newErrors.adminEmail = "Invalid email format";
      valid = false;
    } else {
      newErrors.adminEmail = "";
    }

    // Admin Mobile validation (required, numeric)
    if (!form.adminMobile) {
      newErrors.adminMobile = "Administrator Mobile is required";
      valid = false;
    } else if (isNaN(Number(form.adminMobile))) {
      newErrors.adminMobile = "Administrator Mobile must be numeric";
      valid = false;
    } else {
      newErrors.adminMobile = "";
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault(); // Prevent default form submission

    if (validateForm()) {
      // Collect form data
      const formData = {
        companyTin: form.companyTin,
        companyName: form.companyName,
        directorName: form.directorName,
        directorEmail: form.directorEmail,
        directorMobile: form.directorMobile,
        adminName: form.adminName,
        adminEmail: form.adminEmail,
        adminMobile: form.adminMobile,
        isAccepted: form.isAccepted,
      };

      // Save form data to localStorage
      localStorage.setItem("formData", JSON.stringify(formData));
      try {
        // Dispatch the action to get the PDF (assuming it returns a Blob or URL)
        const actionResult = await dispatch(getIntemnityLetterPdf(formData));

        console.log("Action Result:", actionResult); // Log the entire action to check the structure

        // Extract the payload (the Blob containing the PDF) from the action result
        const pdfBlob = actionResult.payload;

        if (pdfBlob instanceof Blob && pdfBlob.type === "application/pdf") {
          // Create a URL from the Blob
          const url = URL.createObjectURL(pdfBlob);

          // Create a temporary link to trigger the download
          const a = document.createElement("a");
          a.href = url;
          a.download = "Indemnity_letter.pdf"; // Set the download filename
          document.body.appendChild(a); // Append to the document
          a.click(); // Simulate a click to download the file
          document.body.removeChild(a); // Clean up the DOM
          URL.revokeObjectURL(url); // Revoke the object URL to release memory
          dispatch(setIsSubmitted(true));
        } else {
          console.error("No PDF received or unexpected response format");
        }
      } catch (error) {
        console.error("Error fetching PDF:", error);
      }
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
    console.log("nameee", name);
    if (name == "companyTin") {
      let formattedValue = value
        .replace(/\D/g, "")
        .replace(/(\d{3})(?=\d)/g, "$1-");

      setForm({ ...form, [name]: formattedValue });
    }
  };

  return (
    <div style={{ backgroundColor: "white", padding: "20px" }}>
      <p>
        <strong>
          INDEMNITY AGAINST DAMAGES AND LEGAL COSTS ARISING OUT OF THE RELEASE
          OF THE CONTAINERS THROUGH MISHANDLING OF DOCUMENTS PRINTED AT OWN
          PREMISES.
        </strong>
      </p>

      <h6
        style={{
          // fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        Definitions of Terms,
      </h6>
      <ol>
        <li>
          <p>
            <strong>Documents:</strong> EIR IN/OUT - Equipment Interchange
            Receipt (In/Out), EBS - Equipment Booking Slip or any other document
            used during the container clearance.
          </p>
        </li>
        <li>
          <p>
            <strong>Terminal:</strong> TPA 2
          </p>
        </li>
        <li>
          <p>
            <strong>TPA2:</strong> Tanzania Ports Authority Terminal 2
          </p>
        </li>
      </ol>

      <p>
        By using system, your Company authorizes the release and gate out of
        containers using the document printed through the Customer Web Portal
        System at your own premises by your staff using the Login ID as provided
        by TPA Terminal II good office.
      </p>

      <h6
        style={{
          //   fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        You hereby undertake,
      </h6>
      <ol type="a">
        <li>
          To indemnify the terminal for mishandling of the EIR/EBS/INV documents
          and any other document printed through the Customer Portal at your
          office, through any means whatsoever.
        </li>
        <li>
          To indemnify the terminal for illegal sharing of login ID by anybody
          for who it is not intended.
        </li>
        <li>
          To indemnify the Terminal, against all:
          <ol type="i">
            <li>
              Claims that may be made against the Terminal by reason of the
              delivery of the container.
            </li>
            <li>
              Losses, expenses, damages, and penalties the Terminal may sustain
              through the release of the container.
            </li>
          </ol>
        </li>
        <li>
          To pay the full legal costs that the Terminal may incur in defending
          any claim brought with respect to the container.
        </li>
        <li>
          To redeliver the container if it is still in Terminal possession at
          the time of this claim.
        </li>
        <li>
          On demand to put up security to obtain the release of any property
          that may be detained in connection with the release of the container.
        </li>
        <li>
          You further state that, the Terminal is hereby and unequivocally be
          indemnified and held harmless, absolving him from any and all forms,
          types, and degrees of liability, whether direct, indirect,
          consequential, or otherwise, arising out of or in connection with any
          instance, act, omission, or occurrence of mishandling, misuse, or any
          inappropriate handling whatsoever, pertaining to this document. This
          indemnification covers and extends to all potential claims, suits,
          demands, actions, proceedings, losses, damages, costs, expenses,
          penalties, fines, and liabilities of any nature that may arise as a
          consequence of said mishandling, without limitation. This provision
          shall be binding upon all parties, their successors, assigns, and
          representatives.
        </li>
      </ol>

      {/* New Components */}
      <div style={{ marginTop: "25px", textAlign: "center" }}>
        {/* Centered Checkbox with Space */}

        <Form onSubmit={handleSubmit}>
          {/* Centered Checkbox with Space */}
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <input
              type="checkbox"
              disabled={decrptrequestId !== ""}
              style={{ marginRight: "5px" }}
              required
              checked={form.isAccepted === "Y"}
              onChange={(e) => {
                setForm({
                  ...form,
                  isAccepted: e.target.checked ? "Y" : "N",
                });
                console.log("woww", form.isAccepted);
              }}
            />
            I Have Read & Agree With Above <span style={{ color: '#2c7be5', marginLeft: '4px' }}>Terms and Conditions</span>
          </label>

          <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header className="p-2 pb-1" closeButton>
              <Modal.Title>{t("resource_3:termsAndConditions")}</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                height: "500px",
                overflow: "auto",
                fontSize: "12px",
              }}
            >
              <TermsAndConditions />
            </Modal.Body>
          </Modal>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "flex-start",
            }}
          >
            {/* Director's Details */}
            <div
              style={{ width: "45%", marginTop: "10px", marginLeft: "20px" }}
            >
              {/* <h5>Director's Details</h5> */}

              <div
                style={{
                  width: "45%",
                  marginTop: "10px",
                  marginBottom: "10px",
                  textAlign: "left",
                }}
              >
                <h5
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    marginBottom: "10px",
                  }}
                >
                  Director's Details
                </h5>
              </div>

              <div
                style={{
                  width: "45%",
                  marginTop: "10px",
                  marginBottom: "10px",
                  textAlign: "left",
                }}
              >
                <h6>Indemnity References:</h6>
              </div>

              {/* Company TIN */}
              <div className="mb-2">
                <Form.Group
                  controlId="director.companytin"
                  className="d-flex align-items-center"
                >
                  <Form.Label className="fs--1" style={labelStyle}>
                    <span className="mandatory">*</span>Company TIN
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Company TIN"
                    name="companyTin"
                    autoComplete="off"
                    value={form.companyTin}
                    onChange={handleChange}
                    disabled={decrptrequestId != ""}
                    className={`fs--1 form-control ${errors.companyTin ? "is-invalid" : ""
                      }`}
                    style={inputStyle}
                  />
                  <div style={errorStyle}>
                    {errors.companyTin && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.companyTin}
                      </div>
                    )}
                  </div>
                </Form.Group>
              </div>

              {/* Company Name */}
              <div className="mb-2">
                <Form.Group
                  controlId="director.companyname"
                  className="d-flex align-items-center"
                >
                  <Form.Label className="fs--1" style={labelStyle}>
                    <span className="mandatory">*</span>Company Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Company Name"
                    name="companyName"
                    value={form.companyName}
                    disabled={decrptrequestId != ""}
                    autoComplete="off"
                    onChange={handleChange}
                    className={`fs--1 form-control ${errors.companyName ? "is-invalid" : ""
                      }`}
                    style={inputStyle}
                  />

                  <div style={errorStyle}>
                    {errors.companyName && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.companyName}
                      </div>
                    )}
                  </div>
                </Form.Group>
              </div>

              {/* Director Name */}
              <div className="mb-2">
                <Form.Group
                  controlId="director.directorname"
                  className="d-flex align-items-center"
                >
                  <Form.Label className="fs--1" style={labelStyle}>
                    <span className="mandatory">*</span>Director Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Director Name"
                    name="directorName"
                    value={form.directorName}
                    disabled={decrptrequestId != ""}
                    onChange={handleChange}
                    className={`fs--1 form-control ${errors.directorName ? "is-invalid" : ""
                      }`}
                    style={inputStyle}
                  />

                  <div style={errorStyle}>
                    {errors.directorName && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.directorName}
                      </div>
                    )}
                  </div>
                </Form.Group>
              </div>

              {/* Email Address */}
              <div className="mb-2">
                <Form.Group
                  controlId="director.email"
                  className="d-flex align-items-center"
                >
                  <Form.Label className="fs--1" style={labelStyle}>
                    <span className="mandatory">*</span>Email Address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    name="directorEmail"
                    value={form.directorEmail}
                    disabled={decrptrequestId != ""}
                    onChange={handleChange}
                    className={`fs--1 form-control ${errors.directorEmail ? "is-invalid" : ""
                      }`}
                    /* style={{
                      width: "250px", // Ensuring a consistent width
                      height: "38px", // Ensuring a consistent height with other inputs
                    }} */
                    style={inputStyle}
                  />

                  <div style={errorStyle}>
                    {errors.directorEmail && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.directorEmail}
                      </div>
                    )}
                  </div>
                </Form.Group>
              </div>

              {/* Mobile Number */}
              <div className="mb-2">
                <Form.Group
                  controlId="director.mobile"
                  className="d-flex align-items-center"
                >
                  <Form.Label className="fs--1" style={labelStyle}>
                    <span className="mandatory">*</span>Mobile Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Mobile Number"
                    name="directorMobile"
                    value={form.directorMobile}
                    onChange={handleChange}
                    disabled={decrptrequestId != ""}
                    className={`fs--1 form-control ${errors.directorMobile ? "is-invalid" : ""
                      }`}
                    style={inputStyle}
                  />

                  <div style={errorStyle}>
                    {errors.directorMobile && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.directorMobile}
                      </div>
                    )}
                  </div>
                </Form.Group>
              </div>
            </div>

            {/* Administrator Details */}
            <div
              style={{ width: "55%", marginTop: "10px", paddingLeft: "15px" }}
            >
              <div
                style={{
                  width: "45%",
                  marginTop: "10px",
                  marginBottom: "30px",
                  textAlign: "left",
                }}
              >
                <h5
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    marginBottom: "10px",
                  }}
                >
                  Administrator Details
                </h5>
              </div>

              {/* Administrator Name */}
              <div className="mb-2">
                <Form.Group
                  controlId="admin.administratorname"
                  className="d-flex align-items-center"
                >
                  <Form.Label className="fs--1" style={labelStyle}>
                    <span className="mandatory">*</span>Administrator Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Administrator Name"
                    name="adminName"
                    disabled={decrptrequestId != ""}
                    value={form.adminName}
                    onChange={handleChange}
                    className={`fs--1 form-control ${errors.adminName ? "is-invalid" : ""
                      }`}
                    style={inputStyle}
                  />

                  <div style={errorStyle}>
                    {errors.adminName && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.adminName}
                      </div>
                    )}
                  </div>
                </Form.Group>
              </div>

              {/* Email Address */}
              <div className="mb-2">
                <Form.Group
                  controlId="admin.emailaddress"
                  className="d-flex align-items-center"
                >
                  <Form.Label className="fs--1" style={labelStyle}>
                    <span className="mandatory">*</span>Email Address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    name="adminEmail"
                    value={form.adminEmail}
                    disabled={decrptrequestId != ""}
                    onChange={handleChange}
                    className={`fs--1 form-control ${errors.adminEmail ? "is-invalid" : ""
                      }`}
                    style={inputStyle}
                  />

                  <div style={errorStyle}>
                    {errors.adminEmail && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.adminEmail}
                      </div>
                    )}
                  </div>
                </Form.Group>
              </div>

              {/* Mobile Number */}
              <div className="mb-2">
                <Form.Group
                  controlId="admin.mobilenumber"
                  className="d-flex align-items-center"
                >
                  <Form.Label className="fs--1" style={labelStyle}>
                    <span className="mandatory">*</span>Mobile Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Mobile Number"
                    name="adminMobile"
                    value={form.adminMobile}
                    disabled={decrptrequestId != ""}
                    onChange={handleChange}
                    className={`fs--1 form-control ${errors.adminMobile ? "is-invalid" : ""
                      }`}
                    style={inputStyle}
                  />

                  <div style={errorStyle}>
                    {errors.adminMobile && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.adminMobile}
                      </div>
                    )}
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>
          {decrptrequestId == "" && (
            <Button
              variant="primary"
              disabled={decrptrequestId != ""}
              type="submit"
              style={{ marginTop: "30px" }}
            >
              Proceed To Print For Signature & Seal{" "}
              <FontAwesomeIcon icon={faPrint} style={{ marginLeft: "5px" }} />
            </Button>
          )}

          {/* Instructions */}
          {decrptrequestId == "" && (
            <p style={{ marginTop: "10px", fontSize: "12px", color: "blue" }}>
              <strong>Instructions:</strong> Please print the completed
              indemnity letter, sign and seal it manually, and attach it to the
              Registration request. This document is mandatory for the
              Registration.
            </p>
          )}
        </Form>
      </div>
    </div>
  );
};

export default IndemnityLetter;
