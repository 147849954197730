/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useResizeColumns,
  useBlockLayout,
  useRowState,
} from 'react-table';
import { useDispatch } from 'react-redux';
import { updateSelectedAmount } from '../../../app/features/multiBlPayment/slices/multiBlPaymentSlice';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { addSelectionToTableData, removeSelectionfromInstTable, removeSelectionfromTable, updateSelectedInstRow, updateSelectedTableData } from '../../../app/features/dashboard/slices/dashboardSlice';
import { updateSelectedBlListMultiPay } from '../../../app/features/multiBlPayment/slices/multiBlPaymentSlice';
import { useBlDashName } from '../../../hooks/useDashName';
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();

    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      if(rest.checked)return
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Form.Check
        type='checkbox'
        className='form-check fs-0 mb-0 d-flex align-items-center'>
        <Form.Check.Input type='checkbox' ref={resolvedRef} {...rest} />
      </Form.Check>
    );
  }
);

const AdvanceTableWrapper = ({
  children,
  columns,
  data,
  sortable,
  selection,
  selectionColumnWidth,
  pagination,
  perPage = 20,
  onCustomSelectChange,
  onSelectRow,
  selectionInitialState,
  disableRowSelection,
  staticHiddenCols,
  uniqueFilterKey,
  selectedRows,
  isChecked,
  isAllChecked
}) => {
  const dispatch = useDispatch();
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 40,
      width: 120,
      maxWidth: 40,
    }),
    []
  );

  const [objectArray, setObjectArray] = useState([]);
  const [selectedData, setselectedData] = useState([]);
  const [selectedAmt, setSelectedAmt] = useState(0);
  const filterState = useSelector((state) => state.payment.data);
  const selectedAmount = useSelector(
    (state) => state.multiBlPayment.selectedAmountAll
  );
  const selectedBls = useSelector(
    (state) => state.multiBlPayment.selectedRowsMultiPay
  );
  const invoiceDashboardData = useSelector(
    (state) => state.dashboard.invoiceDashboardList
  );
  const selecteCredData = useSelector(
    (state) => state.creditBalance.selectedCreditData
  );
  const currentGridName = useSelector(
    (state) => state.dashboard.currentGridName
  );
  const pmtNumberdata = useSelector(
    (state) => state.paymentBlPayment.pmtNumberdata
  );
  const blDashboardName = useBlDashName() 

  const editPayment = useSelector((state) => state.blPayment.editPayment);
  const amountDetails = useSelector((state) => state.blPayment.amountDetails);
  
  const handleClick = (row) => {
    
    setObjectArray((prevArray) => {
      const newObjectArray = [
        ...prevArray,
        {
          id: row.id,
          amount: row.original.amount,
          localamount: row.original.localamount,
          invID: row.invID,
          invcurr: row.invcurr,
          localccy:row.localccy,
          invtype:row.invtype,
          collector_code: row.collector_code
        },
      ];
      const stringIdCount = {};
      const filteredArray = [];
      const seen = {};
      newObjectArray?.forEach((item) => {
        const key = `${item.id}-${item.amount}`;
        stringIdCount[key] = (stringIdCount[key] || 0) + 1;
      });

      newObjectArray.forEach((item) => {
        const key = `${item.id}-${item.amount}`;
        if (!seen[key]) {
          seen[key] = true;
          filteredArray.push(item);
        }
      });
      
      if (uniqueFilterKey === 'paymentOne') {
        //debugger
        dispatch(updateSelectedAmount(filteredArray));
      }
      return filteredArray;
    });
  };

  useEffect(() => {
    if (selectedAmount.length == 0) {
      setObjectArray([]);
      setselectedData([]);
    }
  }, [selectedAmount]);

  const [forceUpdate, setForceUpdate] = useState(false);
  const {
    getTableProps,
    getTableBodyProps,
    headers,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    pageCount,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
    rows,
    setGlobalFilter,
    hideSelectAll,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,

      data,
      disableSortBy: !sortable,
      initialState: pagination
        ? {
            pageSize: perPage,
            selectedRowIds: selectionInitialState,
            hiddenColumns: staticHiddenCols,
          }
        : {
            pageSize: Math.max(data?.length,perPage),
            selectedRowIds: selectionInitialState,
            hiddenColumns: staticHiddenCols,
          },

      defaultColumn: defaultColumn,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useResizeColumns,
    useBlockLayout,


    (hooks) => {
      const disAllowPaymentCondition =  (i)=>i?.pmtstatus == 'Yes' ||
      i?.pmtreference
      ||(i?.invtypecode=="999") ||
      (selectedAmount?.[0] && selectedAmount[0].collector_code != i?.collector_code) ||
      (((selectedAmount.length??0) > 0) &&
      ((selectedAmount[0]?.invcurr !== i?.invcurr) || (selectedAmount[0]?.id.substring(0, 2)=='CD') !== (i?.invno?.substring(0, 2)=='CD') ||

       (isNaN(i?.localamt) !== isNaN(selectedAmount[0]?.localamount))  ))
      if (selection) {
        if (uniqueFilterKey === 'paymentOne') {
          hooks.visibleColumns.push((columns) => [
            {
              id: 'selection',
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox
                  // disabled
                  disabled={editPayment}
                  onClick={(e) => {
                   if (e.target.checked) {
                      onSelectRow({
                        action: 'add',
                        data: rows.map((r) => r.original),
                      });
                    } else {
                      onSelectRow({
                        action: 'remove',
                        data: rows.map((r) => r.original),
                      });
                    }
                    if (e.target.checked) {
                      if (uniqueFilterKey === 'paymentOne') {
                        if (selectedAmount.length > 0) {
                          const filteredArray1 =
                            invoiceDashboardData.result?.filter((item1) =>
                              selectedAmount.every(
                                (item2) => item2.id !== item1.invno 
                              ) && ((selectedAmount[0].id.substring(0, 2)=='CD') == (item1.invno.substring(0, 2)=='CD'))
                              && (selectedAmount[0].localccy == item1.localccy) && (item1.collector_code == selectedAmount[0].collector_code)
                            );
                          
                          filteredArray1?.forEach((item) => {
                            item.pmtstatus !== 'Yes' &&
                              (!item.pmtreference ||
                                item.pmtreference.length === 0) && item.invoice_cat !='FINAL' &&
                              handleClick({
                                invcurr: item.invcurr,
                                localccy:item.localccy,
                                invID: item.invid,
                                invtype:item.invtype,
                                id: item.invno,
                                collector_code: item.collector_code,
                                original: {
                                  amount:
                                    item.pmtstatus == 'Yes'
                                      ? 'No'
                                      : item.invamt,
                                  localamount:
                                    item.pmtstatus == 'Yes'
                                      ? 'No'
                                      : item.localamt,
                                  
                                },
                              });
                          });
                        } else {
                          invoiceDashboardData.result?.filter((item1)=>
                              ((
                                invoiceDashboardData.result?.[0]
                                .invno.substring(0, 2)=='CD')== (item1.invno.substring(0, 2)=='CD')
                                && (invoiceDashboardData.result?.[0].localccy == item1.localccy))
                                && (invoiceDashboardData.result?.[0].collector_code == item1.collector_code)
                          ).forEach((item) => {
                            item.pmtstatus !== 'Yes' &&
                              (!item.pmtreference ||
                                item.pmtreference.length === 0) &&
                              handleClick({
                                invcurr: item?.invcurr,
                                localccy:item.localccy,
                                invID: item.invid,
                                invtype:item.invtype,
                                id: item.invno,
                                collector_code: item.collector_code,
                                original: {
                                  amount:
                                    item.pmtstatus == 'Yes'
                                      ? 'No'
                                      : item.invamt,
                                  localamount:
                                    item.pmtstatus == 'Yes'
                                      ? 'No'
                                      : item.localamt,
                                },
                              });
                          });
                        }
                      }
                    } else {
                      console.log("aakash 4")
                      dispatch(updateSelectedAmount([]));
                      setObjectArray([]);
                      setselectedData([]);
                    }
                  }}
                  {...getToggleAllRowsSelectedProps()}
                  indeterminate = {selectedAmount?.find(x=>invoiceDashboardData?.result?.find(y=>y.invid == x.invID))}
                  checked={selectedAmount?.[0] && !invoiceDashboardData?.result?.filter((item)=>selectedAmount[0].collector_code === item.collector_code && item.invoice_cat !='FINAL' 
                  ).find(
                    (i)=>!selectedAmount.map((x)=>x.id).includes(i.invno) && !disAllowPaymentCondition(i)) }
                />
              ),
              headerProps: {
                style: {
                  maxWidth: selectionColumnWidth,
                },
              },
              cellProps: {
                style: {
                  maxWidth: selectionColumnWidth,
                },
              },
              Cell: ({ row }) => {
                if (
                  disAllowPaymentCondition(row?.original)
                ) {
                  
                  return (
                    <IndeterminateCheckbox
                      disabled={editPayment}
                      checked={false}
                      
                      onClick={(event) => {
                        if (uniqueFilterKey === 'paymentOne') {
                          
                          if (row?.original?.pmtstatus == 'Yes') {
                            toast.error(
                              t('resource_message:invoiceAlreadyPaid')
                            );
                          } else if((row?.original?.invtypecode=="999")){

                            toast.error(t('Can not select Final Invoice'));
                          }else if (row?.original?.pmtreference) {
                            toast.error(
                              t('resource_message:paymentAlreadyInitiated') +
                                row?.original?.pmtreference
                            );
                          } else if (
                            selectedAmount[0].invcurr !== row?.original?.invcurr
                          ) {
                            toast.error(t('resource_message:multipleCurrency'));
                          }else if (
                            (selectedAmount[0].id.substring(0, 2)=='CD') !== (row?.original?.invno.substring(0, 2)=='CD')
                          ) {
                            toast.error(t('Container Deposite Invoices should be submitted seperately'));
                          }else if((isNaN(row?.original?.localamt) !== isNaN(selectedAmount[0].localamount))){
                            toast.error(t('Can not select multiple local currency'));
                          } else if(selectedAmount?.[0] && selectedAmount[0].collector_code != row?.original?.collector_code){
                            toast.error(
                              t('resource_message:sameCountryValidation')
                            );
                          }
                        }
                      }}
                    />
                  );
                } else {
                  return (
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                      checked={!!selectedAmount.find((v)=>v.id == row?.original?.invno)}
                      onClick={(event) => {
                        
                        if (uniqueFilterKey === 'paymentOne') {
                          if (row?.original?.pmtstatus == 'Yes') {
                            event.target.checked = false;
                            toast.error(
                              t('resource_message:invoiceAlreadyPaid')
                            );
                          } else if (row?.original?.pmtreference) {
                            event.target.checked = false;
                            toast.error(
                              t('resource_message:paymentAlreadyInitiated') +
                                row?.original?.pmtreference
                            );
                          }else {
                            if (event.target.checked) {
                              onSelectRow({
                                action: 'add',
                                data: row.original,
                              });
                            } else {
                              onSelectRow({
                                action: 'remove',
                                data: row.original,
                              });
                            }
                          }

                          if (row.original.unAllocatedAmount) {
                            if (event.target.checked) {
                              const selectedCreditAmount = selecteCredData
                                .map((i) => Number(i.unAllocatedAmount))
                                .reduce((partialSum, a) => partialSum + a, 0);

                              if (
                                selectedCreditAmount > 0 &&
                                (amountDetails > 0) &
                                  (selectedCreditAmount > amountDetails)
                              ) {
                                event.target.checked = false;
                                toast.error(
                                  'Selected Invoice amount is greater than the Credit Balance'
                                );
                              }
                            } else {
                              onSelectRow({
                                action: 'remove',
                                data: row.original,
                              });
                            }
                          }

                          if (row?.original?.pmtstatus !== 'Yes') {
                            if (event.target.checked) {
                              setselectedData((prevArray) => {
                                const newObjectArray = [
                                  ...prevArray,
                                  row?.original,
                                ];

                                dispatch(
                                  updateSelectedBlListMultiPay(newObjectArray)
                                );
                                return newObjectArray;
                              });
                              //  dispatch(updateSelectedBlListMultiPay((item) => [...item, row?.original]));
                              handleClick({
                                invcurr: row?.original?.invcurr,
                                localccy:row?.original?.localccy,
                                invID: row.original.invid,
                                id: row.original.invno,
                                invtype:row.original.invtype,
                                collector_code: row.original.collector_code,
                                original: {
                                  amount:
                                    row.original.pmtstatus == 'Yes'
                                      ? 'No'
                                      : row.original.invamt,
                                  localamount:
                                    row.original.pmtstatus == 'Yes'
                                      ? 'No'
                                      : row.original.localamt,
                                },
                              });
                            } else {
                              const filteredData = selectedAmount.filter(
                                (item) =>
                                  item.id != row.original.invno.toString()
                              );
                              dispatch(updateSelectedAmount(filteredData));
                              setObjectArray(filteredData);
                              const filteredData2 = selectedBls.filter(
                                (item) =>
                                  item.invno != row.original.invno.toString()
                              );

                              dispatch(
                                updateSelectedBlListMultiPay(filteredData2)
                              );
                              setselectedData(filteredData2);
                            }
                          }
                        }
                      }}
                      disabled={
                        editPayment ||
                        (disableRowSelection?.accessor &&
                          disableRowSelection?.value &&
                          row.values[disableRowSelection.accessor] ===
                            disableRowSelection.value)
                      }
                    />
                  );
                }
              },
            },
            ...columns,
          ]);
        }else if(uniqueFilterKey === 'instDashboard'){
          hooks.visibleColumns.push((columns) => [
            {
              id: 'selection',
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox
                  // disabled
                  onClick={(e) => {
                    if (e.target.checked) {
                      if(rows.filter((v)=>v.original.status=='Submitted').length > 0){
                        dispatch(
                          addSelectionToTableData(
                            rows.filter((v)=>v.original.status=='Submitted').map((v) => {
                              return {
                                ...v.original,
                                rowId: v.id,
                              };
                            })
                          )
                        );
                      }
                      
                    } else {
                      dispatch(removeSelectionfromInstTable(
                        rows.map((v) => {
                          return {
                            ...v.original,
                            rowId: v.id,
                          };
                        })
                      ));
                    }
                  }}
                  {...getToggleAllRowsSelectedProps()}
                />
              ),
              headerProps: {
                style: {
                  maxWidth: selectionColumnWidth,
                },
              },
              cellProps: {
                style: {
                  maxWidth: selectionColumnWidth,
                },
              },
              Cell: ({ row }) => {
                if(row.original.status == 'Submitted'){
                  return (
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                      onClick={(event) => {
                        const newSelectedData =
                          selectedRows.length > 0
                            ? [
                                {
                                  ...row.original,
                                  rowId: row.id,
                                },
                              ].concat(selectedRows)
                            : [
                                {
                                  ...row.original,
                                  rowId: row.id,
                                },
                              ];
                        if (event.target.checked) {
                          if(row.original.status == 'Submitted'){
                            dispatch(updateSelectedTableData(newSelectedData));
                          } else {
                            toast.dark('Only submitted Instruction can be assigned.');
                          }
                          
                        } else {
                          
                          dispatch(updateSelectedTableData(
                             selectedRows.filter(
                              (v) => v?.bl_event_id && v?.bl_event_id !== row.original.bl_event_id
                            )
                           ));
                          
                        }
                      }}
                      disabled={
                        disableRowSelection?.accessor &&
                        disableRowSelection?.value &&
                        row.values[disableRowSelection.accessor] ===
                          disableRowSelection.value
                      }
                    />
                  );
                } else {
                  return (
                    <IndeterminateCheckbox
                      checked={false}
                      onClick={(event) => {
                        toast.error('Only submitted Instruction can be assigned.');
                      }}
                      disabled={
                        disableRowSelection?.accessor &&
                        disableRowSelection?.value &&
                        row.values[disableRowSelection.accessor] ===
                          disableRowSelection.value
                      }
                    />
                  );
                }
                
              },
            },
            ...columns,
          ]);
        } else if(uniqueFilterKey == 'mainDashboard') {
          hooks.visibleColumns.push((columns) => [
            {
              id: 'selection',
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox
                  // disabled
                  onClick={(e) => {
                    if (e.target.checked) {
                      dispatch(
                        addSelectionToTableData(
                          rows.map((v) => {
                            return {
                              ...v.original,
                              rowId: v.id,
                            };
                          })
                        )
                      );
                    } else {
                      dispatch(removeSelectionfromTable(
                        rows.map((v) => {
                          return {
                            ...v.original,
                            rowId: v.id,
                          };
                        })
                      ));
                    }
                  }}
                  {...getToggleAllRowsSelectedProps()}
                />
              ),
              headerProps: {
                style: {
                  maxWidth: selectionColumnWidth,
                },
              },
              cellProps: {
                style: {
                  maxWidth: selectionColumnWidth,
                },
              },
              Cell: ({ row }) => {
                return (
                  <IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps()}
                    onClick={(event) => {
                      const newSelectedData =
                        selectedRows.length > 0
                          ? [
                              {
                                ...row.original,
                                rowId: row.id,
                              },
                            ].concat(selectedRows)
                          : [
                              {
                                ...row.original,
                                rowId: row.id,
                              },
                            ];
                      if (event.target.checked) {
                        dispatch(updateSelectedTableData(newSelectedData));
                      } else {
                        dispatch(updateSelectedTableData(
                          selectedRows.filter(
                            (v) => v?.blid && v?.blid !== row.original.blid
                          )
                        ));
                      }
                    }}
                    disabled={
                      disableRowSelection?.accessor &&
                      disableRowSelection?.value &&
                      row.values[disableRowSelection.accessor] ===
                        disableRowSelection.value
                    }
                  />
                );
              },
            },
            ...columns,
          ]);
        }  else {
          hooks.visibleColumns.push((columns) => [
            {
              id:"selection",
              Header:(row)=><IndeterminateCheckbox
              checked={isAllChecked?.(row.data) == 'Y'}
              indeterminate={isAllChecked?.(row.data) == 'S'}
              onClick={()=>{
                if(isAllChecked?.(row?.data) == 'Y'){
                  onSelectRow({ action:'removeAll', rows:row?.data })
                } else {
                  onSelectRow({ action:'addAll', rows:row?.data })
                }
              }}
              ></IndeterminateCheckbox>,
              
              Cell:({row})=><IndeterminateCheckbox
              checked={isChecked?.(row?.original) == 'Y'}
              onClick={()=>{
                if(isChecked?.(row?.original)== 'Y'){
                  onSelectRow({action:'remove', row:row?.original})
                }else{
                  onSelectRow({action:'add', row:row?.original})
                }
              }}
              ></IndeterminateCheckbox>
            },
            ...columns
          ]);
        }
      }
    }
  );
  useEffect(() => {
    if (uniqueFilterKey == 'paymentinvoice')
      if (
        window.location.pathname.includes('blsliderpayment') &&
        columns.length == 2 &&
        pmtNumberdata.length > 0
      ) {
        // setPageSize(pmtNumberdata.length)
        toggleAllRowsSelected(true);
      }
    // rows.forEach(row => {
    //   row.isSelected = true;
    // });
  }, [pmtNumberdata]);

  useEffect(() => {
    if (rows[0]?.original?.pmtReference) {
      const data = rows
        .filter((i) => Object.keys(selectedRowIds).includes(i.id))
        .map((item) => ({
          ...item.values,
          paymentFundingId: item.original.paymentFundingId,
          pmtAmount: item.original.pmtAmount,
          allocatedAmount: item.original.allocatedAmount,
        }));

      onCustomSelectChange({
        list: data,
        rows: selectedRowIds,
      });
    } else {
      if (
        window.location.pathname.includes('blsliderpayment') ||
        (currentGridName == blDashboardName &&
          window.location.pathname.includes('payment'))
      ) {
        const data = rows
          .filter((i) => Object.keys(selectedRowIds).includes(i.id))
          .map((item) => ({
            ...item.values,
            invid: item.original.invid
              ? item.original.invid
              : item.original.invId,
          }));

        onCustomSelectChange({
          list: data,
          rows: selectedRowIds,
        });
      } else {
        const data = rows
          .filter((i) => Object.keys(selectedRowIds).includes(i.id))
          .map((item) => ({
            ...item.values,
            id: item.original.id,
          }));

        onCustomSelectChange({
          list: data,
          rows: selectedRowIds,
        });
      }
    }
  }, [selectedRowIds]);

  const recursiveMap = (children) => {
    return React.Children.map(children, (child) => {
      if (child?.props?.children) {
        return React.cloneElement(child, {
          children: recursiveMap(child.props.children),
        });
      } else {
        if (child?.props?.table) {
          return React.cloneElement(child, {
            ...child.props,
            getTableProps,
            getTableBodyProps,
            headers,
            headerGroups,
            page,
            rows,
            prepareRow,
            canPreviousPage,
            canNextPage,
            nextPage,
            previousPage,
            gotoPage,
            pageCount,
            pageIndex,
            selectedRowIds,
            pageSize,
            setPageSize,
            globalFilter,
            setGlobalFilter,
          });
        } else {
          return child;
        }
      }
    });
  };

  return <>{recursiveMap(children)}</>;
};

export default AdvanceTableWrapper;
