/**
 * Editable partner details table to be shown on registration
 */

import { Button, Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  fetchLinerNameByCountry,
  getRegistrationDocuments,
} from "./actions/Registration";
import {
  addDocumentTableRow,
  removeDocumentTableRow,
  setDocumentTableRow,
} from "./registraionSlice";

import DatePicker from "react-datepicker";
import { getDateFormat } from "../../../helpers/dateFormat";
import { useLocation } from "react-router-dom";
import DataTable from "../../common/components/DataTable";

import { Label } from "semantic-ui-react";
import UploadThing from "../../../components/common/UploadThing";

export default function RegistrationDocumentTable({
  country,
  isDisabled,
  companyType,
  status,
}: any) {
  const { t } = useTranslation();
  const location = useLocation();
  const data = useSelector(
    (state: RootState) => state.registration.documentTable
  );
  const dispatch = useDispatch<AppDispatch>();
  const documentOptions = useSelector(
    (state: RootState) => state.registration.documentOptions
  );
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);
  interface UploadedFiles {
    [key: number]: boolean;
  }
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFiles>({});
  const [verifiedRows, setVerifiedRows] = useState<any>([]);
  useEffect(() => {
    if (country && companyType) {
      dispatch(getRegistrationDocuments({ country, companyType }));
      // needs to be called on view as well to get document names
    }
  }, [country, companyType]);

  const handleVerifyChange = (rowNumber: any, isVerified: any) => {
    dispatch(
      setDocumentTableRow({
        rowNumber,
        verified: isVerified,
      })
    );

    setVerifiedRows((prev: any) => {
      if (isVerified) {
        return [...prev, rowNumber];
      } else {
        return prev.filter((id: any) => id !== rowNumber);
      }
    });
  };

  // Handle "verify all" checkbox change
  const handleVerifyAll = (e: any) => {
    const isChecked = e.target.checked;
    const newVerifiedStatus = isChecked ? "Y" : "N";

    setVerifiedRows(isChecked ? data.map((item) => item.rowNumber) : []);

    data.forEach((item) => {
      dispatch(
        setDocumentTableRow({
          rowNumber: item.rowNumber,
          verified: newVerifiedStatus,
        })
      );
    });
  };

  const tableColumnConfig = [
    {
      accessor: "docId",
      Header: "Document Name",
      width: 150,
      maxWidth: 300,
      Cell: (rowData: any) => {
        const { docId, m, rowNumber } = rowData.row.original;

        if (m.attachment)
          return (
            <Form.Select className={`fs--1 form-control`} disabled={false}>
              <option>
                {documentOptions.find((i: any) => i.value == docId)?.text ?? ""}
              </option>
            </Form.Select>
          );
        return (
          <Form.Select
            className="fs--1 form-control"
            value={docId}
            disabled={isDisabled}
            // disabled={status == 1 || status == 3 ? true : false}
            onChange={(e) => {
              dispatch(
                setDocumentTableRow({
                  rowNumber,
                  docId: e.target.value,
                })
              );
            }}
          >
            {documentOptions.map((i: any) => (
              <option value={i.value} key={i.value}>
                {i.text}
              </option>
            ))}
          </Form.Select>
        );
      },
    },
    {
      accessor: "number",
      Header: "Document No",
      width: 180,
      maxWidth: 200,
      Cell: (rowData: any) => {
        const { number, rowNumber, error } = rowData.row.original;
        return (
          <input
            type="text"
            className={`fs--1 form-control ${error.number ? "is-invalid" : ""}`}
            defaultValue={number || ""}
            disabled={status == 1 || status == 3 ? true : false}
            onBlur={(e) => {
              dispatch(
                setDocumentTableRow({
                  rowNumber,
                  number: e.target.value.trim(),
                })
              );
            }}
          />
        );
      },
    },
    {
      accessor: "issueDate",
      Header: "Issue Date",
      width: 150,
      maxWidth: 180,
      Cell: (rowData: any) => {
        const { issueDate, rowNumber, error } = rowData.row.original;
        return (
          <DatePicker
            selected={issueDate || null}
            portalId="root-portal"
            onChange={(date) => {
              dispatch(
                setDocumentTableRow({
                  rowNumber,
                  issueDate: date,
                  error: { ...error, issueDate: false },
                })
              );
            }}
            strictParsing={true}
            className={`fs--2 form-control ${error.issueDate ? "is-invalid" : ""
              }`}
            timeIntervals={5}
            disabled={status == 1 || status == 3 ? true : false}
            dateFormat={getDateFormat()}
            isClearable={true}
            popperProps={{
              strategy: "fixed",
            }}
            // disabled={isDisabled}
            maxDate={new Date()}
          />
        );
      },
    },
    {
      accessor: "validityDate",
      Header: "Validity Date",
      width: 150,

      maxWidth: 180,
      Cell: (rowData: any) => {
        const { validityDate, rowNumber, m, error, docId } =
          rowData.row.original;

        return (
          <DatePicker
            selected={validityDate || null}
            portalId="root-portal"
            onChange={(date) => {
              dispatch(
                setDocumentTableRow({
                  rowNumber,
                  validityDate: date,
                  error: { ...error, validityDate: false },
                })
              );
            }}
            className={`fs--2 form-control ${error.validityDate ? "is-invalid" : ""
              }`}
            timeIntervals={5}
            dateFormat={getDateFormat()}
            isClearable={true}
            popperProps={{
              strategy: "fixed",
            }}
            strictParsing={true}
            disabled={
              /* isDisabled || */ status == 1 ||
              status == 3 ||
              !m.validityDate /* || (country === 'TZ' && !documentOptions.find((i: any) => i.value == docId)?.m.validityDate) */
            }
            minDate={new Date()}
          />
        );
      },
    },
    {
      accessor: "attachmentName",
      Header: country !== "TZ" ? "Attachment" : "Upload",
      width: 200,
      maxWidth: 280,
      Cell: (rowData: any) => {
        const {
          rowNumber,
          attachmentName,
          loading,
          regPartnerDocumentId,
          error,
        } = rowData.row.original;
        if (isDisabled)
          return (
            <div className="h-100 d-flex align-items-center">
              <span
                className="fs--1 text-truncate"
                style={{
                  color: "#2c7be5",
                  cursor: "pointer",
                  maxWidth: "100%",
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                //  style={{ color: "#2c7be5", cursor: "pointer" }}
                //title={attachmentName} // Shows full name on hover
                title={attachmentName}
                onClick={() => {
                  window.open(
                    `/api/user-management/v1/customer/openDocument?id=${regPartnerDocumentId}&moduleTp=DOCUMENT&linerId=${linerId}`
                  );
                }}
              >
                {attachmentName}
              </span>
            </div>
          );
        return (
          <UploadThing
            name={attachmentName}
            invalid={error.attachment && !uploadedFiles[rowNumber]}
            loading={loading}
            fileType={["PDF"]}
            labelWidth={"150px"}
            fileLimit={5}
            clear={() => {
              dispatch(
                setDocumentTableRow({
                  rowNumber,
                  attachmentName: "",
                  serverPath: null,
                  serverName: null,
                  loading: 0,
                })
              );
              setUploadedFiles((prev) => ({ ...prev, [rowNumber]: false }));
            }}
            pending={(loading) => {
              dispatch(
                setDocumentTableRow({
                  rowNumber,
                  loading: loading,
                })
              );
            }}
            fulfilled={(name, serverPath, serverName) => {
              dispatch(
                setDocumentTableRow({
                  rowNumber,
                  attachmentName: name,
                  serverPath: serverPath,
                  serverName: serverName,
                  loading: 100,
                })
              );
              setUploadedFiles((prev) => ({ ...prev, [rowNumber]: true }));
            }}
            selected={(name) => {
              dispatch(
                setDocumentTableRow({
                  rowNumber,
                  attachmentName: name,
                })
              );
              setUploadedFiles((prev) => ({ ...prev, [rowNumber]: true }));
            }}
            failed={() => {
              dispatch(
                setDocumentTableRow({
                  rowNumber,
                  loading: -1,
                })
              );
              setUploadedFiles((prev) => ({ ...prev, [rowNumber]: false }));
            }}
          />
        );
      },
    },
    !location.pathname.includes("/registrationmaster") && {
      accessor: "m",
      Header: "",
      width: 20,
      maxWidth: 20,
      Cell: (rowData: any) => {
        const { m, rowNumber } = rowData.row.original;
        if (m.attachment) return <></>;
        return (
          <Button
            variant="falcon-warning"
            style={{
              background: "transparent",
              border: "none",
              boxShadow: "none",
              padding: "0px",
            }}
            onClick={() => {
              dispatch(removeDocumentTableRow({ rowNumber }));
            }}
          >
            <FontAwesomeIcon icon={faTrash} size="sm" />
          </Button>
        );
      },
      disableSort: true,
    },

    location.pathname.includes("/registrationmaster") && /* &
      status === 2 &&  */ {
      accessor: "verify", // Ensure accessor matches the field name in the data

      Header: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "8px", marginTop: "4px" }}>Verify</span>
          <Form.Check
            type="checkbox"
            onChange={handleVerifyAll}
            disabled={status === 1 || status == 0 || status == 3}
            checked={
              data.length > 0 && data.every((item) => item.verified === "Y")
            }
          />
        </div>
      ),
      width: 200,
      maxWidth: 300,
      sortable: false,
      disableSortBy: true,
      Cell: (rowData: any) => {
        const { rowNumber, verified, isApproved } = rowData.row.original;

        return (
          <Form.Check
            type="checkbox"
            checked={verified === "Y" || isApproved == "Y"}
            disabled={status === 1 || status == 0 || status == 3}
            onChange={(e) => {
              dispatch(
                setDocumentTableRow({
                  rowNumber,
                  verified: verified === "Y" ? "N" : "Y", // Ensure this is properly updating state
                })
              );
            }}
            style={{ marginLeft: "10px" }}
          />
        );
      },
    },
  ];
  if (documentOptions.length < 1 && (!isDisabled || data.length < 1))
    return <></>;
  return (
    <div className="col-lg-12 mt-2 mb-2 demmurageTable d-flex flex-column">
      {!isDisabled && (
        <Button
          variant="falcon-primary"
          style={{
            width: "120px",
            marginRight: "10px",
            marginBottom: "10px",
          }}
          size="sm"
          onClick={() => {
            dispatch(addDocumentTableRow());
          }}
        >
          <div className="d-flex">
            <FontAwesomeIcon icon={faPlus} className="me-2 mt-1 " />
            <span>Document</span>
          </div>
        </Button>
      )}
      <DataTable
        gridName="REG_DOCUMENT_TABLE"
        columns={tableColumnConfig}
        data={data}
        shortPrepend="reg_dt"
        perPage={100}
        sortable={true}
        searchable={false}
        showColumnFilter={false}
        selection={false}
        pagination={false}
        seekPagination={false}
        showExport={false}
        localFilter={true}
        uniqueFilterKey="documentTable1"
        autoHeight={true}
        tableMinHeight={300}
      />
    </div>
  );
}
