import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { resetQuickPayTable } from "../quickpay/quickPaySlice";
import { useBackNavigation } from "../../../hooks/BackNavigation";
import {
  PayPalScriptProvider,
  usePayPalCardFields,
  PayPalCardFieldsProvider,
  PayPalButtons,
  PayPalCardFieldsForm,
} from "@paypal/react-paypal-js";
function AppCard({ payload, quickPay }) {
  var orderId;

  // Yup validation schema
  const validationSchema = Yup.object({
    cardName: Yup.string().required("Cardholder name is required."),
    cardNumber: Yup.string()
      .matches(
        /^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}$/,
        "Invalid card number format. Ensure it's in the format XXXX-XXXX-XXXX-XXXX."
      )
      .required("Card number is required."),
    cardExpiry: Yup.string()
      .matches(
        /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
        "Invalid expiry date format. Use MM/YY."
      )
      .test(
        "expiry-date",
        "Card expiry date cannot be in the past.",
        function (value) {
          if (!value) {
            return false; // If the value is empty or undefined, return false
          }

          const currentDate = new Date();
          const [month, year] = (value && value.split("/")) || [];

          // Check if the month and year are valid
          if (month < 1 || month > 12 || isNaN(year)) {
            return false;
          }

          const expiryDate = new Date(`20${year}`, month - 1);
          return expiryDate >= currentDate;
        }
      )
      .required("Expiry date is required."),
    cvv: Yup.string()
      .matches(/^[0-9]{3}$/, "CVV must be exactly 3 digits.")
      .required("CVV is required."),
  });

  const paypalRef = useRef();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onBack = useBackNavigation();

  const initialOptions = {
    clientId:
      "AV1xxFp72k264HFbt5pwbKcj31pP3oCX-8qJZYuOvRL4wYhum6JdpXZRlfJKeHcuFJ2RwuszDuCcePWi",
    currency: "USD",
    intent: "capture",
    components: "buttons,card-fields",
  };

  const createOrder = async (data, actions) => {
    // let orderId = "";
    return axios
      .post(
        "/api/payment-gateway/v1/gateway/paypal/order/initiation",
        payload,
        {
          headers: {
            Accept: "*/*",
          },
        }
      )

      .then((response) => {
        //window.open(response?.data?.links[1]?.href, '_blank', 'height=500,width=600');
        orderId = response?.data?.id;
        console.log("orderid123", response?.data?.id, orderId);
        return response?.data?.id;
      })
      .catch(function (error) {
        console.error("Error creating order:", error);
        throw error;
      });
  };
  const onApprove = async (data, actions) => {
    console.log("orderid", orderId);
    return axios
      .post(`/api/payment-gateway/v1/gateway/paypal/order/${orderId}/capture`, {
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response?.data?.error) {
          if (
            response?.data?.error.details?.[0]?.issue == "INSTRUMENT_DECLINED"
          ) {
            actions.restart();
            return;
          }
          throw new Error(
            response?.data?.error.details?.[0]?.description ??
              "Payment Unsuccessful"
          );
        }
        if (quickPay) {
          dispatch(resetQuickPayTable());
          navigate("/quickpay");
          toast.success(
            `Payment successful, details will be mailed to ${payload.email}`,
            {
              theme: "colored",
            }
          );
          return;
        }
        toast.success("Payment successful", {
          theme: "colored",
        });
        onBack();
      })
      .catch(function (error) {
        console.error("Error creating order:", error);
        throw error;
      });
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <Formik
        initialValues={{
          cardName: "",
          cardNumber: "",
          cardExpiry: "",
          cvv: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          createOrder(values)
            .then((orderId) => {
              console.log("Order created", orderId);
              onApprove(orderId);
            })
            .catch((error) => {
              console.error("Error creating order", error.message || error);
            });
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <h3
              style={{
                fontSize: "18px",
                fontWeight: "600",
                marginBottom: "1rem",
                textAlign: "center",
                color: "#333333",
              }}
            >
              Enter Your Card Details
            </h3>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
              }}
            >
              <div style={{ position: "relative" }}>
                <label
                  htmlFor="card-name"
                  style={{
                    position: "absolute",
                    top: "-8px",
                    left: "12px",
                    backgroundColor: "#ffffff",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "0 4px",
                    color: "#666666",
                  }}
                >
                  Card Name
                </label>
                <Field
                  id="card-name"
                  type="text"
                  name="cardName"
                  required
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(
                      /[^a-zA-Z0-9\s-]/g,
                      ""
                    );
                  }}
                  style={{
                    width: "100%",
                    padding: "12px",
                    fontSize: "14px",
                    border: "1px solid #d1d5db",
                    borderRadius: "8px",
                  }}
                />
                <ErrorMessage
                  name="cardName"
                  component="div"
                  style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                />
              </div>

              <div style={{ position: "relative" }}>
                <label
                  htmlFor="card-number"
                  style={{
                    position: "absolute",
                    top: "-8px",
                    left: "12px",
                    backgroundColor: "#ffffff",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "0 4px",
                    color: "#666666",
                  }}
                >
                  Card Number
                </label>
                <Field
                  id="card-number"
                  type="text"
                  name="cardNumber"
                  required
                  maxLength={19} // Maximum length including hyphens
                  onInput={(e) => {
                    let value = e.target.value.replace(/-/g, ""); // Remove existing hyphens
                    value = value.replace(/\D/g, ""); // Remove non-numeric characters
                    value = value.slice(0, 16); // Limit to 16 digits
                    value = value.match(/.{1,4}/g)?.join("-") || ""; // Add hyphens after every 4 digits
                    e.target.value = value; // Update the value of the input
                  }}
                  style={{
                    width: "100%",
                    padding: "12px",
                    fontSize: "14px",
                    border: "1px solid #d1d5db",
                    borderRadius: "8px",
                  }}
                />
                <ErrorMessage
                  name="cardNumber"
                  component="div"
                  style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                />
              </div>

              <div style={{ display: "flex", gap: "1rem" }}>
                <div style={{ flex: 1, position: "relative" }}>
                  <label
                    htmlFor="card-expiry"
                    style={{
                      position: "absolute",
                      top: "-8px",
                      left: "12px",
                      backgroundColor: "#ffffff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "0 4px",
                      color: "#666666",
                    }}
                  >
                    Card Expiry
                  </label>
                  <Field
                    id="card-expiry"
                    type="text"
                    name="cardExpiry"
                    placeholder="MM/YY"
                    required
                    maxLength={5} // Format length is fixed: MM/YY
                    onInput={(e) => {
                      let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                      if (value.length > 2) {
                        value = value.slice(0, 2) + "/" + value.slice(2, 4); // Add "/" after the month
                      }
                      e.target.value = value.slice(0, 5); // Restrict to "MM/YY"
                    }}
                    style={{
                      width: "100%",
                      padding: "12px",
                      fontSize: "14px",
                      border: "1px solid #d1d5db",
                      borderRadius: "8px",
                    }}
                  />
                  <ErrorMessage
                    name="cardExpiry"
                    component="div"
                    style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                  />
                </div>

                <div style={{ flex: 1, position: "relative" }}>
                  <label
                    htmlFor="card-cvv"
                    style={{
                      position: "absolute",
                      top: "-8px",
                      left: "12px",
                      backgroundColor: "#ffffff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "0 4px",
                      color: "#666666",
                    }}
                  >
                    Card CVV
                  </label>
                  <Field
                    id="card-cvv"
                    type="text"
                    name="cvv"
                    required
                    maxLength={3} // Restricts input to a maximum of 3 characters
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                    }}
                    style={{
                      width: "100%",
                      padding: "12px",
                      fontSize: "14px",
                      border: "1px solid #d1d5db",
                      borderRadius: "8px",
                    }}
                  />
                  <ErrorMessage
                    name="cvv"
                    component="div"
                    style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
                  />
                </div>
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                style={{
                  width: "100%",
                  padding: "12px",
                  fontSize: "16px",
                  color: "#ffffff",
                  backgroundColor: isSubmitting ? "#cccccc" : "#0070ba",
                  border: "none",
                  borderRadius: "8px",
                  cursor: isSubmitting ? "not-allowed" : "pointer",
                  fontWeight: "600",
                }}
              >
                {isSubmitting ? "Processing..." : "Pay"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </PayPalScriptProvider>
  );
}

export default AppCard;
